<script>
import { CONSTANTS } from '../constants.js';
import ItemCard from '../components/ItemCard.vue'
import Histogram from '../components/IPCCard.vue'
import PipeStatusPieChartCard from '../components/PipeStatusPieChartCard.vue';
import HorizontalBarCard from '../components/HorizontalBarCard.vue'
import TUTJ from '../components/TUTJ.vue';
import { barData, horisontalMultiBarData, formatDataPie } from '../dataformatter';
import OrderBreakdown from './OrderBreakdown.vue';

export default {
  name: 'DrillPipeStatistics',
  props: {
    drillPipeData: {
      type: Object,
    },
  },
  components: {
    ItemCard,
    Histogram,
    PipeStatusPieChartCard,
    HorizontalBarCard,
    TUTJ,
    OrderBreakdown
  },
  computed: {
    machining_card_title: () => {
      return CONSTANTS.MACHINING_CARD_TITLE
    },
    hardbanding_card_title: () => {
      return CONSTANTS.HARDBANDING_CARD_TITLE
    }
  },
  data() {
    return {
      orderDataTableHeaders: [
        {
          text: 'Order nr',
          align: 'start',
          sortable: true,
          value: 'Order_No'
        },
        { text: "Asset", sortable: true, value: "Asset" },
        { text: "Quantity", sortable: true, value: "Quantity" },
        { text: "Connection pin recut sum", sortable: true, value: "Machining_Pin_Sum_Recut" },
        { text: "Pin SRF", value: "Machining_Pin_SRF" },
        { text: "Connection box recut sum", sortable: true, value: "Machining_Box_Sum_Recut" },
        { text: "Box SRF", value: "Machining_Box_SRF" },
        { text: "Hardbanding pin sum", value: "Hardbanding_Pin_Sum" },
        { text: "Hardbanding box sum", value: "Hardbanding_Box_Sum" }


      ],
    }
  },
  methods: {
    barData,
    horisontalMultiBarData,
    formatDataPie,
    handleRowClick(row) {
      this.$emit("rowClicked", row)
    }
  }
}
</script>


<template>
  <v-container>
    <!-- 1st row -->
    <v-row class="ma-2">
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E" elevation="0">
          <v-card-title>General</v-card-title>
          <ItemCard :itemObject="drillPipeData.itemData" />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>IPC Status</v-card-title>
          <Histogram
            :IPCData="barData('IPC Status', Object.keys(this.drillPipeData.IPC), Object.values(this.drillPipeData.IPC))"
            :title="'IPC Status'" />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" dark color="#1C355E">
          <v-row justify="center"
            style="width: 100%; height: 200px; margin: auto;">
            <TUTJ :pieData="formatDataPie(this.drillPipeData.TU_Tube, 'Tube')">
            </TUTJ>
          </v-row>
          <v-row justify="center" style="width: 100%; margin: auto;">
            <TUTJ
              :pieData="formatDataPie(this.drillPipeData.TJ_Tooljoint, 'Tool Joint')">
            </TUTJ>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- 2nd row -->
    <v-row class="ma-2">
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>{{ 'TS Pin' + ' (Min criteria: ' +
            this.drillPipeData.minimum_values.TS_Pin + ')' }}</v-card-title>
          <Histogram
            :IPCData="barData('Tongspace Pin', this.drillPipeData.tongspace.Pin.x, this.drillPipeData.tongspace.Pin.y)"
            :title="'Tongspace Pin'" />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>{{ 'TS Box' + ' (Min criteria: ' +
            this.drillPipeData.minimum_values.TS_Box + ')' }}</v-card-title>
          <Histogram
            :IPCData="barData('Tongspace Box', this.drillPipeData.tongspace.Box.x, this.drillPipeData.tongspace.Box.y)"
            :title="'Tongspace Box'" />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>{{ 'Box OD' + ' (Min criteria: ' +
            this.drillPipeData.minimum_values.OD_Box + ')'
          }}</v-card-title>
          <Histogram
            :IPCData="barData('OD Box', this.drillPipeData.od.Box.x, this.drillPipeData.od.Box.y)"
            :title="'Box OD'" />
        </v-card>
      </v-col>
    </v-row>

    <!-- 3rd row -->
    <v-row class="ma-2">
      <v-col cols="5">
        <v-card class="pa-2 cardWidth" min-height="600px" max-height="600px" dark
          color="#1C355E">
          <v-card-title>Wall Status</v-card-title>
          <Histogram
            :IPCData="barData('Wall', this.drillPipeData.wall.x, this.drillPipeData.wall.y)"
            :title="'Wall status'"></Histogram>
        </v-card>
      </v-col>
      <v-col cols="7">
        <v-card class="pa-2 cardWidth" min-height="600px" max-height="600px" dark
          color="#1C355E">
          <v-card-title>{{ hardbanding_card_title }}</v-card-title>
          <HorizontalBarCard
            :barData="horisontalMultiBarData(this.drillPipeData.Hardbanding)"
            :height="'500'">
          </HorizontalBarCard>
        </v-card>
      </v-col>
    </v-row>

    <!-- 4th row -->
    <v-row class="ma-2">
      <v-col cols="12">
        <v-card class="pa-2 cardWidth" min-height="800px" dark color="#1C355E">
          <v-card-title>{{ machining_card_title + " " +
            this.drillPipeData.itemData.Quantity + "x Drill
                      Pipes"}}</v-card-title>
          <HorizontalBarCard
            :barData="horisontalMultiBarData(this.drillPipeData.Machining_Data)"
            :height="'100%'">
          </HorizontalBarCard>
        </v-card>
      </v-col>
    </v-row>

    <!-- 5th row -->
    <v-row class="ma-2">
      <v-col cols="12">
        <OrderBreakdown
          :orderData="{ listOfOrderData: this.drillPipeData.listOfOrderData, orderDataTableHeaders: orderDataTableHeaders }"
          @rowClicked="handleRowClick" :equipmentType="'Drill Pipe'">
        </OrderBreakdown>
      </v-col>
    </v-row>
  </v-container>
</template>