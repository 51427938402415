<template>
  <v-dialog :value="value" width="400" @input="test">
    <v-card dark color="#1C355E">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">Certificates</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list rounded light dense>
        <v-list-item  class="text-button text-left red--text" v-if="item['PDF'] == '' && item['PDF2'] == '' && item['PDF3'] == '' && item['PDF4'] == '' && item['PDF5'] == ''">
          <v-list-item-content>
            <v-list-item-title><b> No documents</b></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="text-button text-left " @click="getPDF(item['PDF'])" :class="item['PDF'] == '' ? 'd-none' : ''">
          <v-list-item-content>
            <v-list-item-title><b>{{item['Item_No']}} Doc1</b></v-list-item-title>
            <v-list-item-subtitle class="text-button text-left " >{{item['Date1']}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
         <v-list-item class="text-button text-left " @click="getPDF(item['PDF2'])"  :class="item['PDF2'] == '' ? 'd-none' : ''">
          <v-list-item-content>
            <v-list-item-title ><b>{{item['Item_No']}} Doc2</b></v-list-item-title>
            <v-list-item-subtitle >{{item['Date2']}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
         <v-list-item class="text-button text-left " @click="getPDF(item['PDF3'])" :class="item['PDF3'] == '' ? 'd-none' : ''">
          <v-list-item-content>
            <v-list-item-title ><b>{{item['Item_No']}} Doc3</b></v-list-item-title>
            <v-list-item-subtitle >{{item['Date3']}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
         <v-list-item class="text-button text-left " @click="getPDF(item['PDF4'])" :class="item['PDF4'] == ''  ? 'd-none' : ''">
          <v-list-item-content>
           <v-list-item-title ><b>{{item['Item_No']}} Doc4</b></v-list-item-title>
           <v-list-item-subtitle >{{item['Date4']}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
         <v-list-item class="text-button text-left " @click="getPDF(item['PDF5'])" :class="item['PDF5'] == '' ? 'd-none' : ''">
          <v-list-item-content>
            <v-list-item-title ><b>{{item['Item_No']}} Doc5</b></v-list-item-title>
            <v-list-item-subtitle >{{item['Date5']}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'cert_menu',
  props: [
    'item',
    'value'
  ],
  data () {
    return {
      loaders: {
        wire: false,
        DTexcel: false,
        DTpdf: false
      },
      dialogload: false,
      padding_class: 'pl-2',
      title: 'Certificates'
    }
  },
  computed: {
    ...mapGetters(['get_dialog_load'])
  },
  methods: {
    ...mapActions(['set_error_state']),
    test () {
      this.dialogHeaderUpdate(false)
      this.$emit('input')
    },
    dialogHeaderUpdate (error, description) {
      if (error) {
        this.title = 'Error'
        this.subtitle =
          description.split('_')[0] +
          ' ' +
          description.split('_')[1] +
          ' PDF does not exist'
        this.colorClass = 'error'
      } else {
        this.title = 'DT Number'
        this.subtitle = this.item.DTNO
      }
    },
    getPDF(link) {
      console.log(link)
      if (link !== "") {
        window.open(link, "_blank");
      } else {
        this.set_error_state(true);
      } 
    },
    // getDTPDF() {
    //   this.loaders['DTpdf'] = true;
    //   axios
    //     .get("/getDeliveryPDF", {
    //       params: { dtno: this.DTNO },
    //     })
    //     .then((response) => {
    //       this.loaders['DTpdf'] = false;
    //       window.open(response.data.pdflink, "_blank");
    //     });
    // },
    // getDTEXCEL() {
    //   this.loaders['DTexcel'] = true;
    //   axios
    //     .get("/getDTExcel", {
    //       params: { dtno: this.DTNO },
    //     })
    //     .then((response) => {
    //       this.loaders['DTexcel'] = false;
    //       window.open(response.data.excellink, "_blank");
    //     });
    // },
  }
}
</script>

<style scoped>
</style>
