import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export const globalMixin = {
  data () {
    return {
      tubularData: null,
      BHAData: null
    }
  },
  watch: {
    tubularData: function (newVal) {
      this.ODP_data.Tubular = newVal
    },
    BHAData: function (newVal) {
      this.ODP_data.BHA = newVal
    }
  },
  computed: {
    ...mapGetters(['get_data_load', 'getPipeType', 'getFleet', 'getAsset', 'get_dialog_load'])
  },
  methods: {
    ...mapActions(['set_data_load', 'set_error_state', 'set_dialog_load']),
    $filterAsset (data, fleet, asset, assetFieldName, type) {
      //  Returns input data filtered on asset.
      if (type === 'BHA') {
        if (fleet === true) {
          return data[type]
        }
        return data[type].filter(order => {
          return order[assetFieldName] === asset
        })
      } else {
        if (fleet === true) {
          return data[type]
        } else {
          return data[type].filter(order => {
            return order[assetFieldName] === asset
          })
        }
      }
    },
    $formatDate (date) {
      if (date !== '') {
        let dateFormatted = new Date(date * 1000)
        dateFormatted = ('0' + dateFormatted.getDate()).slice(-2) + '.' +
          ('0' + (dateFormatted.getMonth() + 1)).slice(-2) + '.' +
          dateFormatted.getFullYear()

        return dateFormatted
      } else {
        return ''
      }
    },
    $filterData (data, fieldToFilter, fieldFilterValue, type) {
      return data[type].filter(element => {
        return element[fieldToFilter] === fieldFilterValue
      })
    },
    $getEXCELglobal (type, item, pdf_params) {
      var pipetype = type
      this.set_data_load(true)
      this.set_dialog_load(true)
      if (type === undefined) {
        pipetype = this.getPipeType
      }

      if (pipetype === 'Tubular') {
        axios
          .get(pdf_params[0][pipetype], {
            params: { id_no: item[pdf_params[1]] }
          })
          .then(response => {
            this.set_data_load(false)
            this.set_dialog_load(false)
            window.open(response.data.excellink, '_blank')
          })
      } else if (pipetype === 'BHA') {
        this.set_data_load(false)
        this.set_dialog_load(false)
      }
    },
    $getPDFglobal (type, item, pdf_params) {
      this.set_dialog_load(true)
      this.set_data_load(true)
      var pipeType = type
      // console.log(type === undefined)
      if (type === undefined) {
        // console.log('type not defined')
        pipeType = this.getPipeType
      }
      // pdf_params is a list of pdf parameters, in this order:
      // [route, id_name, container_location, pdf_location1, description, pdf_location2, pdf_location3 ]
      //   console.log(pdf_params[5])
      //   console.log(pdf_params[0][type])
      //   console.log(pdf_params);
      //   console.log(pipeType);
      if (pipeType === 'Tubular') {
        // console.log('Global PDF func detected tubular');
        axios
          .get(pdf_params[0][pipeType], {
            params: { id_no: item[pdf_params[1]] }
          })
          .then(response => {
            if (response.data) {
              window.open(response.data.pdflink, '_blank')
              this.set_data_load(false)
              this.set_dialog_load(false)
            }
          })
      } else if (pipeType === 'BHA') {
        axios
          .get(pdf_params[0][pipeType], {
            params: {
              id_name: pdf_params[1],
              type: true,
              container_location: pdf_params[2],
              id_no: item[pdf_params[1]],
              pdf_location: pdf_params[3],
              description: pdf_params[4],
              pdf_location2: pdf_params[5],
              pdf_location3: pdf_params[6],
              serial_no: pdf_params[7],
              description_name: pdf_params[8],
              report_id: item.Report_ID
            }
          })
          .then(response => {
            window.open(response.data.pdflink, '_blank')
            this.set_data_load(false)
            this.set_dialog_load(false)
          })
      }
    },
    $initialDataLoad (backendRoutes, params, fieldname) {
      this.set_data_load(true)
      let tubularResp = null
      axios.get(backendRoutes.Tubular, {
        params: params
      })
        .then((resp) => {
          tubularResp = []
          if (fieldname !== '' && resp) {
            tubularResp = this.$formatData(resp.data, [fieldname])
          }
          return axios.get(backendRoutes.BHA, {
            params: params
          })
        })
        .then((resp) => {
          this.BHAData = []
          if (fieldname !== '' && resp) {
            this.BHAData = this.$formatData(resp.data, [fieldname])
          }
          this.tubularData = tubularResp
          this.set_data_load(false)
        })
    },
    $formatData (datalist, datefields) {
      if (Array.isArray(datalist)) {
        datalist.forEach(entry => {
          for (let i = 0; i < datefields.length; i++) {
            const fieldname = datefields[i]
            entry.Date_Formatted = this.$formatDate(entry[fieldname])
          }
        })
      }

      return datalist
    }
  }
}
