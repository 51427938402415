<template>
  <v-container fluid>
<!--    <v-card>
      <v-card-title>
         OCTG
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search "
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="test"
        :headers="headers"
        :items="desserts"
        :search="search"
      >
        <template v-slot:item.action="{ item }">
          <v-btn
            dark
            x-small
            text
            color="black"
            @click="getPDF(item.Order_No)"
          >
            <v-icon>description</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>-->
    <data_table
      :displayname="'OCTG'"
      :options="false"
      :search_text="search_text"
      :items="ODP_data.Tubular"
      :headers="headers"
      :loader="get_data_load"
      :getPDF_func="$getPDFglobal"
      :pdf_params="pdfparams"
      :type="'Tubular'"
      :defaultSort="'Inspection_Date'"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import data_table from '@/components/data_table.vue'
export default {
  name: 'octg',
  components: { data_table },
  data () {
    return {
      pdfparams: [{ Tubular: '/getoctgPDF' }, 'Order_No'],
      search_text: 'Search (Order No, Ref etc..)',
      search: '',
      headers: [
        {
          text: 'Order No',
          align: 'start',
          sortable: true,
          value: 'Order_No'
        },
        { text: 'String No', value: 'String_Number' },
        { text: 'Serials', value: 'Serials', align: ' d-none' },
        { text: 'Returned From', value: 'Returned_From' },
        { text: 'Inspection Date', value: 'Inspection_Date' },
        { text: 'Ref', value: 'Ref' },
        { text: 'Quantity', value: 'Quantity' },
        { text: 'Item No', value: 'Item_No' },
        { text: 'Weight', value: 'Weight' },
        { text: 'Grade', value: 'Grade' },
        { text: 'Connection', value: 'Connection' },
        { text: 'Spec', value: 'Spec' },
        { text: 'Equipment', value: 'Equipment' },
        { text: 'PDF', value: 'action', sortable: false }
      ],
      ODP_data: { Tubular: [], BHA: [] }
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'get_data_load'])
  },
  mounted () {
    this.$initialDataLoad(this.$route.meta.backendRoutes, { username: this.$store.getters.getUsername }, 'Inspection_Date')
    /* this.test = true
    axios
      .get('/getoctg', {
        params: { username: this.$store.getters.getUsername }
      })
      .then(response => {
        this.desserts = response.data
        this.test = false
      }) */
  },
  methods: {
    // getPDF (orderno) {
    //   this.test = true
    //   axios
    //     .get('/getoctgPDF', {
    //       params: { orderno: orderno }
    //     })
    //     .then(response => {
    //       this.test = false
    //       window.open(response.data.pdflink, '_blank')
    //     })
    // }
  }
}
</script>

<style>
</style>
