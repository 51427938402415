<template>
  <v-container fluid>
<!--    <v-card >
      <v-card-title>
        Pre Invoice
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search (Item number, Total Yard etc..)"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    <v-data-table :loading="get_data_load" :headers="headers[$store.getters.getPipeType]" :items="$filterAsset(this.ODP_data, this.getFleet, this.getAsset, 'Asset', $store.getters.getPipeType)" :search="search">
      <template v-slot:item.action="{ item }">
        <v-btn dark x-small text color="black" @click="$getPDFglobal({'Tubular':'/getPreinvPDF', 'BHA':'/getPDF'},'Order_No', 'ODP_BHA_Pre_Invoice_V2',item.Order_No,
        'ODP_BHA_Pre_Invoice_V2_Print','Pre_Invoice','ODP_BHA_Pre_Invoice_Repair_Print','ODP_BHA_Pre_Invoice_V2_Machining_Print')">
          <v-icon>description</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    </v-card>-->
    <data_table
      :displayname="'Pre Invoice'"
      :search_text="search_text"
      :options="false"
      :loader="get_data_load"
      :headers="headers[$store.getters.getPipeType]"
      :items="$filterAsset(this.ODP_data, this.getFleet, this.getAsset, 'Asset', $store.getters.getPipeType)"
      :getPDF_func="$getPDFglobal"
      :pdf_params="pdfparams"
      :type="$store.getters.getPipeType"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import data_table from '@/components/data_table.vue'

export default {
  name: 'preinvoice',
  components: { data_table },
  data () {
    return {
      tabs: ['Tubular', 'BHA'],
      search_text: 'Search (Item number, Total Yard etc..)',
      search: '',
      pdfparams: [{ Tubular: '/getPreinvPDF', BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Pre_Invoice_V2',
        'ODP_BHA_Pre_Invoice_V2_Print', 'Pre_Invoice', 'ODP_BHA_Pre_Invoice_Repair_Print', 'ODP_BHA_Pre_Invoice_V2_Machining_Print'],
      headers: {
        Tubular: [
          { text: 'Order', align: 'start', sortable: true, value: 'Order_No' },
          { text: 'Returned From', value: 'Returned_From' },
          { text: 'Return Date', value: 'Return_Date' },
          { text: 'Ref', value: 'Ref' },
          { text: 'Item Number', value: 'Item_Number' },
          { text: 'Equipment', value: 'Equipment' },
          { text: 'Weight', value: 'Weight' },
          { text: 'Grade', value: 'Grade' },
          { text: 'Connection', value: 'Connection' },
          { text: 'Inspection Specification', value: 'Inspection_Specification' },
          { text: 'PDF', value: 'action', sortable: false },
          { text: 'Asset', value: 'Asset' }
        ],
        BHA: [
          { text: 'Order', align: 'start', sortable: true, value: 'Order_No' },
          { text: 'Returned From', value: 'Returned_From' },
          { text: 'PO', value: 'PO' },
          { text: 'Ref', value: 'Ref' },
          { text: 'PDF', value: 'action', sortable: false, align: 'right' }
        ]
      },
      ODP_data: { Tubular: [], BHA: [] },
      dataLoaded: false
    }
  },
  computed: {
    ...mapGetters(['getFleet', 'getUsername', 'getAsset', 'getPipeType', 'get_data_load'])

  },
  mounted () {
    this.$initialDataLoad(this.$route.meta.backendRoutes, { username: this.$store.getters.getUsername }, 'Return_Date')
    /* this.set_data_load(true)
    var resp_tub = 0
    axios
      .get('/getPreinv', {
        params: { username: this.$store.getters.getUsername }
      })
      .then(response => {
        resp_tub = response.data
        console.log(this.ODP_data.Tubular)
      })
      .then(() => {
        axios.get('/getPreInvoiceBHA', {
          params: { username: this.$store.getters.getUsername }
        })
          .then((response) => {
            this.ODP_data.Tubular = resp_tub
            // Check if any elements;
            if (response.data[1] === 401) {
            // No BHA Components, remove BHA from tabs
              this.ODP_data.BHA = []
            } else {
              this.ODP_data.BHA = response.data[0]
            }
            this.set_data_load(false)
          })
      }) */
  },
  methods: {
    ...mapActions(['set_data_load'])
  }
}
</script>

<style>
</style>
