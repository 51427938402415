// This file contains functions that are used in the different components to 
// process raw data and transform it, rendering it usable - this includes 
// diagram options as well.




// seriesName: string
// seriesData: object with data
export const barData = (seriesName, xAxisData, yAxisData) => {
  let output = {};
  output['series'] = [
    {
      name: seriesName,
      data: yAxisData,
    }
  ];

  output['options'] = {
    chart: {
      type: 'bar',
      foreColor: '#fff',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: 'dark',
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: xAxisData,
    },
  }

  return output;
}

export const horisontalMultiBarData = (listOfBarData) => {
  // Abbreviations mapping, universal for this function. Means that it includes all mappings even if they are not used every time. Does not break the usability.
  const yAxisMapper = {
    "OK": "OK",
    "FR": "Field Repair",
    "RF": "Reface",
    "SRF": "Shop Reface",
    "PS": "Pitting Seal",
    "PT": "Pitting Thread",
    "GS": "Galling Seal",
    "GT": "Galling Shoulder",
    "MTD": "Mechanical Thread Damage",
    "MSD": "Mechanical Seal Damage",
    "TL": "Thread Length Incorrect",
    "OT": "Over Torque",
    "REJ": "Reject", // Hardbanding and Machining
    "STA": "Stabbing Damage",
    "SUM": "Sum Recut",
    "D": "Damage", // Hardbanding
    "E": "Eccentric", // Hardbanding,
    "F": "Flush", // Hardbanding,
    "S": "Spalling", // Hardbanding,
    "DS18D": "Damage Softlayer and 18 Degree", // Hardbanding
    "SUM HB": "Sum Hardbanding", // Hardbanding
  };

  let output = {};
  output['series'] = listOfBarData.map((barDataElement) => {
    return { data: Object.values(barDataElement.data), name: barDataElement.name, color: barDataElement.color }
  });

  output['options'] = {
    chart: {
      type: 'bar',
      foreColor: '#fff',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    tooltip: {
      theme: 'dark',
      followCursor: true,
      shared: true,
      intersect: false,
      y: {
        title: {
          formatter: (value, { seriesIndex, dataPointIndex, w }) => {
            return `${yAxisMapper[w.globals.labels[dataPointIndex]]} ${value}`
          }
        }
      },
    },
    xaxis: {
      categories: Object.keys(listOfBarData[0].data), // All elements will have same keys
      labels: {
        show: true,
        align: 'center',
        style: {
          colors: ['#fff'],
          fontSize: '12px',
        },
      }
    },
    yaxis: {
      labels: {
        show: true,
        align: 'left',
        style: {
          colors: ['#fff'],
          fontSize: '16px',
        },
      },
      tooltip: {
        enabled: false,
      }
    },
    legend: {
      show: true,
    }
  }

  return output;
}

export const formatDataPie = (data, chartTitle) => {
  if (data === undefined) {
    return {};
  }
  let pieData = {};
  pieData['series'] = Object.values(data);
  pieData['options'] = {
    chart: {
      width: '100%',
      type: 'pie',
      minHeight: '200px'
    },
    labels: Object.keys(data),
    title: {
      text: chartTitle,
      style: {
        color: '#fff',
        fontSize: '20px',
        fontFamily: 'Arial',
        fontWeight: 'lighter'
      }
    },
    theme: {
      monochrome: {
        enabled: true,
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5
        }
      }
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + '%']
      }
    },
    legend: {
      horizontalAlign: "left",
      position: 'right',
      show: true,
      labels: {
        colors: ['#fff'],
      },
      fontSize: '14px',
    }
  };
  return pieData;
};