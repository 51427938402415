<script>
import {CONSTANTS} from '../constants.js';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'Histogram',
  props: {
    IPCData: {
      type: Object,
    },
    },
  components: {
    VueApexCharts
  },
  data () {
    return {
      CARD_TITLE: CONSTANTS.IPC_CARD_TITLE,
    }
  },
}
</script>


<template>
    <VueApexCharts type="bar" :options="IPCData.options" :series="IPCData.series"></VueApexCharts>
</template>