<script>
export default {
  name: 'ItemCard',
  props: {
    itemObject: {
      type: Object,
    }

  },
  data() {
    return {
    }
  }

}
</script>

<template>
  <v-list color="#1C355E">
    <v-list-item class="text-left align-self-start"
      v-for="(itemProperty, itemKey) in itemObject" :key="itemKey">
      <v-list-item-title>
        {{ itemKey }}: {{ itemProperty }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>