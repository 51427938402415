import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import firebase from 'firebase'
import axios from 'axios'
import moment from 'moment'
import VuePaginate from 'vue-paginate'

Vue.use(VuePaginate)

Vue.prototype.moment = moment

Vue.config.productionTip = false

axios.defaults.baseURL = 'https://oceandatapool-server.herokuapp.com/' // production
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URI  // env variable


axios.interceptors.request.use(config => {
  if (store.getters.getToken) {
    config.headers.AUTHORIZATION = store.getters.getToken
    config.headers.USER = store.getters.getUsername
    config.headers.USERNAME = store.getters.getEmail
  }
  return config
})

axios.interceptors.response.use((response) => {
  if (response.data.AUTH_STATUS === 'FAIL') {
    store.dispatch('logOut')
  } else if (response.data.AUTH_STATUS === 'ERROR') {
    firebase.auth().currentUser.getIdToken(true)
      .then((token) => {
        store.dispatch('setToken', token)
        router.push(this.$route.path)
      })
      .catch((err) => {
        console.log('error: ' + err)
        store.dispatch('logOut')
      })
  }
  return response
}, (error) => {
  if (error.response.status === 404) {
    console.log(error.response)
    store.dispatch('set_error_state', true)
  }
  store.dispatch('set_data_load', false)
  store.dispatch('set_dialog_load', false)
})

// Your web app's Firebase configuration
let app = ''
var firebaseConfig = {
  apiKey: 'AIzaSyAGQQVDBI-KXEWGHhWF7GcZ6n-ApeLf56I',
  authDomain: 'oimr-a32a7.firebaseapp.com',
  projectId: 'oimr-a32a7',
  storageBucket: 'oimr-a32a7.appspot.com',
  messagingSenderId: '989198931518',
  appId: '1:989198931518:web:e0e12025ccc6cb3327636f',
  measurementId: 'G-8V8XK22BF5'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})
