<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'HorizontalBar',
  props: {
    barData: {
      type: Object,
    },
    height: "500",
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {

    }
  }

}
</script>

<template>
  <VueApexCharts type="bar" :options="barData.options" :series="barData.series"
    :height="height"></VueApexCharts>
</template>

<style></style>