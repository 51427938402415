<template>
  <v-container class="mt-10">
    <!-- Stack the columns on mobile by making one full-width and the other half-width -->
    <v-row align="center" class="justify-center align-center fill-height">
      <v-col cols="12" md="12">
        <v-card color="transparent" elevation="0" tile>
              <div style="color: white" class="text-h4">
                <b>{{ getUsername }}</b>
              </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- Columns are always 50% wide, on mobile and desktop -->
    <v-row>
      <v-col v-for="(card, index) in cards1" :key="`card-${index}`" cols="6">
        <v-card
          :to="card.path"
          tile
          dark
          @click="chooseCustomer"
          height="250px"
          :style="{
            'background-color': `rgb(${card.color.red}, ${card.color.green}, ${card.color.blue})`
          }"
        >
          <v-card-title class="justify-center fill-height text-h4">{{ card.name }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="customerDialog" max-width="400px" persistent>
      <v-card>
        <v-toolbar color="#1C355E" dark>
          <v-spacer/>
          <v-toolbar-title>Choose Customer</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selected_customer"
                label="Customer"
                :items="getCustomerList"
                item-text="Customer"
                return-object
                required>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" plain @click="customerDialog = false; selected_customer = ''">Cancel</v-btn>
        <v-btn text color="success" plain @click="confirmCustomer" :disabled="!selected_customer">Confirm</v-btn>
      </v-card-actions>
      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/router/index.js'
import axios from 'axios'
export default {
  name: 'Home',
  props: {
    source: String
  },
  data () {
    return {
      customerDialog: false,
      selected_customer: '',
      allCustomers: [],
      cards1: [
        {
          name: 'User Overview',
          color: {
            red: '32',
            green: '64',
            blue: '96'
          },
          path: '/users'
        },
        // {
        //   name: 'Customer Overview',
        //   color: {
        //     red: '132',
        //     green: '218',
        //     blue: '222'
        //   },
        //   path: '/customers'
        // },
        {
          name: 'View as Customer',
          color: {
            red: '66',
            green: '165',
            blue: '245'
          },
          path: ''
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setUsername']),
    chooseCustomer () {
      this.customerDialog = true
    },
    confirmCustomer () {
      console.log(this.selected_customer.Customer)
      this.setUsername(this.selected_customer.Customer)
      this.customerDialog = false
      router.replace('/')
    }
  },
  computed: {
    ...mapGetters(['getEmail', 'getUsername', 'getAsset', 'getAssetlist', 'getCustomerList'])
  }
}
</script>

<style lang="css" scoped>
input {
  float: left;
}
</style>
