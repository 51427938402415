<script>
export default {
  name: 'OrderBreakdown',
  props: {
    orderData: {
      type: Object,
    },
    equipmentType: {
      type: String,
    }
  },
  computed: {
  },
  methods: {
    itemHeadersMethod() {
      return this.itemHeaders
    }
  }
}
</script>


<template>
  <v-card class="pa-2 cardWidth" dark color="transparent">
    <v-card-title>Order breakdown for {{ this.orderData.listOfOrderData[0].Item_No
    }}</v-card-title>
    <v-data-table :items="this.orderData.listOfOrderData"
      :headers="this.orderData.orderDataTableHeaders" class="custom-table"
      v-if="this.orderData !== null">
      <template v-slot:item="{ item }">
        <tr @click="$emit('rowClicked', item)">
          <td>{{ item.Order_No }}</td>
          <td>{{ item.Asset || 'N/A' }}</td>
          <td>{{ item.Quantity }}</td>
          <td>{{ item.Machining_Pin_Sum_Recut }}</td>
          <td v-if="equipmentType !== 'OCTG'">{{ item.Machining_Pin_SRF }}</td>
          <td>{{ item.Machining_Box_Sum_Recut }}</td>
          <td v-if="equipmentType !== 'OCTG'">{{ item.Machining_Box_SRF }}</td>
          <td v-if="equipmentType === 'Drill Pipe'">
            {{ item.Hardbanding_Pin_Sum }}</td>
          <td v-if="equipmentType === 'Drill Pipe'">
            {{ item.Hardbanding_Box_Sum }}</td>

        </tr>

      </template>

    </v-data-table>

  </v-card>
</template>