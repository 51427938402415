<template>
  <v-container fluid>
    <v-row class="d-flex justify-space-between">
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="500" dark :color="'#1C355E'"
          :elevation="0">
          <v-row>
            <v-col cols="5" class="pa-2 ml-2">
              <v-card class="pa-2" dark :color="'#1C355E'" :elevation="0">
                <v-card-subtitle class="pa-0 text-button" style="text-align:left">
                  ITEM NO
                </v-card-subtitle>
                <v-card-title class="pa-0 text-button"
                  style="font-size:1.2rem !important">
                  {{ item_info["Item_No"] }}
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="5" class="pa-2 ml-2">
              <!-- <v-card class="pa-2" dark :color="'#1C355E'" :elevation="0"> -->
              <!-- <v-card-subtitle class="pa-0 text-button"
                style="text-align:left">
                ORDER NO
              </v-card-subtitle> -->
              <!-- <v-card-title class="pa-0 text-button"
                style="font-size:1.2rem !important">
                {{item_info["Order_No"]}}
              </v-card-title> -->
              <v-autocomplete :items="orderIds" item-text="orderno" item-value="orderno" return-object dark label="Order No" @change="changedOrderNo" v-model="order_no"></v-autocomplete>
              <!-- </v-card> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-row class="pa-2 mt-2">
                <v-card-title style="font-size:1rem; font-weight:bold"
                  class="pa-0 ms-2"> {{ item_info["Tube_OD"] }}
                  {{ this.$route.params.equipment }}</v-card-title>
              </v-row>
              <v-row class="pa-2 pl-0 mt-2 ml-2">
                <span style="font-weight:bold" class="mr-2">Inspection
                  date:</span><span>{{ item_info["Inspection_Date"] }}</span>
              </v-row>
              <v-row class="pa-2 pl-0 mt-2 ml-2">
                <span style="font-weight:bold" class="mr-2">Grade:</span><span>{{
                  item_info["Grade"] }}</span>
              </v-row>
              <v-row class="pa-2 pl-0 mt-2 ml-2">
                <span style="font-weight:bold" class="mr-2">Range:</span><span>{{
                  item_info["Range"] }}</span>
              </v-row>
              <v-row class="pa-2 pl-0 mt-2 ml-2">
                <span style="font-weight:bold" class="mr-2">Weight:
                </span><span>{{ item_info["Weight"] }}</span>
              </v-row>
              <v-row class="pa-2 pl-0 mt-2 ml-2">
                <span style="font-weight:bold;" class="mr-2">Conn:</span>
                <span>{{ item_info["Connection"] }}</span>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="500" dark :color="'#1C355E'"
          :elevation="0">
          <v-row>
            <v-col cols="12">
              <v-card-subtitle class="text-no-wrap pt-1 ps-2"
                style="font-size:1.3rem;">IPC status</v-card-subtitle>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <VerticalBar v-if="load_chart" :data="IPC_chartdata"
                :options="IPC_options"></VerticalBar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="500" dark :color="'#1C355E'"
          :elevation="0">
          <v-row>
            <v-col cols="12">
              <v-card-subtitle class="text-no-wrap pt-1 ps-2"
                style="font-size:1.3rem;">Wall status</v-card-subtitle>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <VerticalBar v-if="load_chart" :data="wall_chartdata"
                :options="wall_options"></VerticalBar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <data_table :displayname="'Inspection data'" :loader="get_data_load"
          :options="false" :headers="current_data_header"
          :search_text="search_text" :items="current_data" :getPDF_func="null"
          :pdf_params="null" :type="$store.getters.getPipeType" />
      </v-col>
      <v-col cols="4">
        <data_table :displayname="'Changes from previous inspection'"
          :loader="get_data_load" :options="false" :headers="diff_header"
          :search_text="search_text_diff" :items="diff_data" :getPDF_func="null"
          :pdf_params="null" :type="$store.getters.getPipeType" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import data_table from '@/components/data_table.vue'
import VerticalBar from './VerticalBar.vue'
export default {
  name: 'iteminspectionhistory',
  components: {
    data_table,
    VerticalBar
  },
  data() {
    return {
      load_chart: false,
      search_text: "",
      search_text_diff: "",
      current_data: [],
      previous_data: [],
      order_no: '',
      diff_data: [],
      item_info: {},
      orderIds: [],
      current_data_header: [
        { text: 'Serial no', value: 'Serial_No' },
        { text: 'Date', value: 'Inspection_Date' },
        { text: 'Box HB OD', value: 'Box_HB_OD' },
        { text: 'Box OD', value: 'Box_OD' },
        { text: 'Wall', value: 'Wall' },
        { text: 'IPC', value: 'IPC' },
        { text: 'Box TL', value: 'Box_TL' },
        { text: 'Box TS', value: 'Box_TS' },
        { text: 'Pin TL', value: 'Pin_TL' },
        { text: 'Pin TS', value: 'Pin_TS' },
        { text: 'Spec', value: 'Spec' },
      ],
      diff_header: [
        { text: 'Serial no', value: 'Serial_No' },
        { text: 'Order no', value: 'Order_No' },
        { text: 'Box HB OD', value: 'Box_HB_OD' },
        { text: 'Box OD', value: 'Box_OD' },
        { text: 'Wall', value: 'Wall' },
      ],
      BGC_mapping: {
        "1": "#4CAF50",
        "2": "#81C784",
        "3": "#FFEB3B",
        "4": "#FFC107",
        "5": "#F44336",
        "A": "#4CAF50",
        "B": "#81C784",
        "C": "#FFEB3B",
        "D": "#FFC107",
        "E": "#F44336",
      },
      IPC_chartdata: {
        labels: [],
        datasets: [
          {
            fill: false,
            axis: "y",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
            skipNull: true,
          }
        ]
      },
      IPC_options: {
        indexAxis: 'x',
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontColor: 'white'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: 'white'
            }
          }]
        }
      },
      wall_chartdata: {
        labels: [],
        datasets: [
          {
            fill: false,
            axis: "y",
            data: [],
            backgroundColor: ["#4CAF50", "#81C784", "#FFEB3B", "#F44336"],
            borderCollor: ["#4CAF50", "#81C784", "#FFEB3B", "#F44336"],
            borderWidth: 1,
            minBarLength: 1,
          }
        ]
      },
      wall_options: {
        indexAxis: 'x',
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontColor: 'white'
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: 'white'
            }
          }]
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getAsset', 'getAssetlist', 'getFleet', 'getPipeType', 'get_data_load'])
  },
  // mounted() {
  //   axios
  //   .get('')
  // }

  mounted() {
    axios.get('/getAllOrders', {params: {username: this.getUsername}})
    .then((resp) => {
      this.orderIds = resp.data.filter((order) => order.scope === "Rig Return" && (order.spec==="NS2" || order.spec==="NS2_VTI") || order.spec === "DS1_Cat5")
    })    
  },
  methods: {
    ...mapActions(["set_data_load"]),
    dateFormat(date) {
      return this.$formatDate(date)
    },
    changedOrderNo() {
      axios
      .get('/getInspectionTallies', {
        params: {
          orderno: this.order_no.orderno,
          itemnr: this.order_no.itemno,
          equipment: this.order_no.equipment,
        }
      })
      .then(response => {
        const serial_data = response.data.serials;
        this.IPC_chartdata["datasets"][0]["data"] = response.data.IPC.data;
        this.IPC_chartdata["labels"] = response.data.IPC.labels;
        this.wall_chartdata["datasets"][0]["data"] = response.data.Wall.data;
        this.wall_chartdata["labels"] = response.data.Wall.labels;
        this.item_info = response.data.item_info;
        for (let i = 0; i <= this.IPC_chartdata["datasets"][0]["data"].length; i++) {
          if (this.IPC_chartdata["datasets"][0]["data"][i] === null) {
            this.IPC_chartdata["datasets"][0]["data"].splice(i, 1);
            this.IPC_chartdata["labels"].splice(i, 1);
            i--;
          }
        }
        for (const element of this.IPC_chartdata["labels"]) {
          // Setting colors...
          this.IPC_chartdata["datasets"][0]["backgroundColor"].push(this.BGC_mapping[element])
        }

        const data = [];
        const prev_data = [];
        const diff_data = [];
        Object.keys(serial_data).forEach(function (key) {
          // serial_data[key][0]["Inspection_Date"] = this.dateFormat(serial_data[key][0]["Inspection_Date"])
          data.push(serial_data[key][0])
          if (serial_data[key].length > 2) {
            console.log(key)
            console.log(serial_data[key])
            console.log(serial_data[key][2])
            serial_data[key][1]["Serial_No"] = key
            serial_data[key][2]["Serial_No"] = key
            serial_data[key][2]["Order_No"] = serial_data[key][1]["Order_No"]
            prev_data.push(serial_data[key][1])
            diff_data.push(serial_data[key][2])
          }
        })
        console.log("Diff data before set: ", diff_data)
        this.current_data = data;
        this.previous_data = prev_data;
        this.diff_data = diff_data;
        console.log(this.diff_data)
        this.load_chart = true;
        this.set_data_load(false)
      })
    }
  }
}
</script>

<style>
.cardWidth.v-sheet.v-card {
  border: 1px solid !important;
  border-color: white;
}
</style>
