<template>
  <v-container class="mt-10">
    <v-row justify="center" align="center" class="align-center justify-center fill-height">
      <v-col cols="12" md="12">
        <v-card color="transparent" elevation="0" tile>
              <div style="color: white" class="text-h4">
                <b>{{ getUsername }}</b>
              </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="3" align="center">
        <v-card @click="setFleet" :height="card_height" :width="card_width" dark :color="card_color" elevation="0">
          <v-card-title primary-title class="justify-center fill-height text-h6">
            Fleet Inventory
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-for="card in getAssetlist" :key="card" justify="center" align="center">
      <v-col cols="3" align="center">
        <v-card @click="test(card)" :height="card_height" :width="card_width" dark :color="card_color" outlined elevation="0">
          <v-card-title primary-title class="justify-center fill-height text-h6">
            {{ card }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "asset",
  data() {
    return {
      card_height: '60px',
      card_width: '300px',
      card_color: 'rgba(66, 165, 245, 0.3)'
    };
  },
  methods: {
    ...mapActions(['setasset','setfleet']),
    test(card)  {
      this.setasset({ asset: card })
      this.$router.replace('home')
    },
    setFleet() {
      this.setasset({ asset: 'Fleet Inventory' })
      this.setfleet({ fleet: true })
      this.$router.replace('home')
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["getUsername", "getAssetlist"])
  }
};
</script>
<style lang="css" scoped>
.asset-card {
  border: 1px solid red;
}
</style>
