import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import racks from './modules/racks'
import misc from './modules/misc'
import createPersistedState from 'vuex-persistedstate'
import { globalMixin } from '../mixins/global_functions.js'
Vue.use(Vuex)
Vue.mixin(globalMixin)

export default new Vuex.Store({

  modules: {
    auth,
    racks,
    misc
  },
  plugins: [
    createPersistedState({
      key: 'vuex-store',
      paths: [
        'auth.email',
        'auth.username',
        'auth.assetlist',
        'auth.excludedAssets',
        'auth.routeslist',
        'auth.loadingcomplete',
        'auth.asset',
        'auth.fleet',
        'auth.token',
        'auth.isAdmin',
        'auth.pipeType',
        'auth.customerList',
        'misc.home_image_loader',
        'misc.data_load',
        'misc.error',
        'misc.dialog_load'

      ]
    })
  ]
})
