<template>
  <v-container >
    <v-card color="transparent" elevation="0" tile dark>
      <v-card-title class="align-left justify-left text-h5">
        <b>BHA Inventory</b>
      </v-card-title>
    </v-card>
    <v-divider dark></v-divider>
    <v-row align="center" justify="center" v-for="row in rows" :key="row" cols="12" sm="1">
      <v-col v-for="n in getChunk" :key="n">
        <v-card height="80" :color="computeColor(n,row)" tile elevation="20" class="pa-0">
          <v-row>
            <v-flex><b>{{n}}{{row}}</b></v-flex>
          </v-row>
          <v-row align="left">
            <v-col align="left">
              <v-flex text-xs-left>
                <v-btn fab x-small v-for="n in getData(n,row)" :key="n">
                  
                </v-btn>
              </v-flex>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  name: "BHAInventory",
  data() {
    return {
      racks: [],
      rows: ["A", "B", "C", "D", "E", "F", "G", "H"],
      currentrack: 1,
      chunk: Array.from(Array(54).keys())
    };
  },
  mounted() {
    axios
      .get("/getInventoryBHA", {
        params: { username: this.$store.getters.getUsername }
      })
      .then(response => {
        console.log(response.data);
        this.racks = response.data;
      });
  },
  methods: {
    getData(n, row) {
      let arr = this.racks[1][n + row];
      if (arr === undefined) {
        return 0;
      } else {
        return arr.length;
      }
    },
    computeColor(n, row) {
      if (this.racks[1][n + row] === undefined) {
        return "red";
      } else {
        return "green";
      }
    }
  },

  computed: {
    getChunk() {
      return this.chunk.slice((this.currentrack * 9) - 8,(this.currentrack * 9)+1)
    }
  }
};
</script>