<template>
  <v-container>
    <a id="downloadAnchorElem" style="display: none"></a>
      <v-snackbar v-model="snackbar" :color="color" :timeout="timeout" top>
        {{ text }}
      </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'KCAD',
  data () {
    return {
      snackbar: true,
      text: ':)',
      color: 'primary',
      timeout: 4000,
      backendRoutes: {
        dp: '/dpjson',
        dc: '/dcjson',
        hw: '/hwjson'
      }
    }
  },
  mounted () {
    const backendURL = this.backendRoutes[this.$route.query.q]
    this.text = 'Loading data...'
    this.snackbar = true
    this.color = '#204060'
    this.timeout = -1
    axios
      .get(backendURL)
      .then(response => {
        this.text = 'Successfully downloaded data'
        this.snackbar = true
        this.color = 'success'
        this.timeout = 5000
        var data = response.data
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(data))
        var dlAnchorElem = document.getElementById('downloadAnchorElem')
        dlAnchorElem.setAttribute('href', dataStr)
        const filename = this.$route.query.q + '_oimr.json'
        dlAnchorElem.setAttribute('download', filename)
        dlAnchorElem.click()
      })
      .catch(response => {
        console.log(response)
        this.color = 'error'
        this.snackbar = true
        this.text = 'Error: Could not fetch data'
      })
  }
}
</script>

<style>
</style>
