<template>
  <v-dialog :value="value" width="400" @input="test">
    <v-card dark color="#1C355E">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
          <v-list-item-title class="headline">{{ item['DT_No'] }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
        <v-progress-linear
          :active="get_dialog_load"
          :indeterminate="get_dialog_load"
          absolute
          color="blue"
        ></v-progress-linear>
      <v-divider></v-divider>
      <v-list rounded light dense>
        <v-list-item @click="$getPDFglobal('Tubular', item, [{'Tubular':'/getDeliveryPDF'}, 'DT_No'])">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">DT summary/Tally report PDF</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$getPDFglobal('Tubular', item, [{'Tubular':'/getDTExcel'},'DT_No'])">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">Tally report EXCEL</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="getWire(item)">
          <v-list-item-content>
            <v-list-item-title class="text-button text-left">Wire Sling PDF</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'delivery_pdf_menu',
  props: [
    'item',
    'value'
  ],
  data () {
    return {
      loaders: {
        wire: false,
        DTexcel: false,
        DTpdf: false
      },
      dialogload: false,
      padding_class: 'pl-2',
      title: 'DT Number'
    }
  },
  computed: {
    ...mapGetters(['get_dialog_load'])
  },
  methods: {
    ...mapActions(['set_error_state']),
    test () {
      this.dialogHeaderUpdate(false)
      this.$emit('input')
    },
    dialogHeaderUpdate (error, description) {
      if (error) {
        this.title = 'Error'
        this.subtitle =
          description.split('_')[0] +
          ' ' +
          description.split('_')[1] +
          ' PDF does not exist'
        this.colorClass = 'error'
      } else {
        this.title = 'DT Number'
        this.subtitle = this.item.DTNO
      }
    },
    // getDTPDF() {
    //   this.loaders['DTpdf'] = true;
    //   axios
    //     .get("/getDeliveryPDF", {
    //       params: { dtno: this.DTNO },
    //     })
    //     .then((response) => {
    //       this.loaders['DTpdf'] = false;
    //       window.open(response.data.pdflink, "_blank");
    //     });
    // },
    // getDTEXCEL() {
    //   this.loaders['DTexcel'] = true;
    //   axios
    //     .get("/getDTExcel", {
    //       params: { dtno: this.DTNO },
    //     })
    //     .then((response) => {
    //       this.loaders['DTexcel'] = false;
    //       window.open(response.data.excellink, "_blank");
    //     });
    // },
    getWire (item) {
      if (item.Wiresling !== '') {
        window.open(item.Wiresling, '_blank')
      } else {
        this.set_error_state(true)
      }
    }
  }
}
</script>

<style scoped>
</style>
