<template>
  <v-container fluid class="mt-7">
    <!-- <pdf_menu :dialog="dialog" :eq="eq" :currentOrdernr="currentOrdernr" :currentrec="currentrec" :currentCOCHB="currentCOCHB" :currentCOCMC="currentCOCMC"></pdf_menu> -->
    <bha_pdf_menu v-model="pdfdialogO"
    :eq="eq"
    :subtitle="currentOrdernr"
    :currentOrdernr="currentOrdernr"
    :bha="bhaDialogO"
    :currentReceiveNo="currentrec"
    :currentCOCHB="currentCOCHB"
    :currentCOCMC="currentCOCMC"
    :itemWithProperties="itemWithProperties"
    :bhaInspURL="'/inspectionBHA?q='+String(currentOrdernr)"
    >
    </bha_pdf_menu>

    <v-row>
      <v-col cols="12">
        <v-card color="transparent" elevation="0" tile dark>
              <v-card-title class="align-center justify-center text-h4"><b>Completed Orders</b></v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card class="px-10" outlined tile color="transparent" dark>
          <v-btn
            class="mb-3"
            color="transparent"
            elevation="0"
            to="/orderconfirm"
          >Orders</v-btn>
          <v-data-table
            height="250"
            :loading="get_data_load"
            :headers="header_order[$store.getters.getPipeType]"
            :items="filterOrders"
            :items-per-page="5"
            class="elevation-1 custom-table"
          >
            <template v-slot:item.action="{ item }">
              <v-btn dark x-small text @click="getPDF($store.getters.getPipeType,item,[])">
                <v-icon>description</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.Return_Date ="{ item }">
              <span>{{ item.Date_Formatted }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="px-10" outlined tile color="transparent" dark>
          <v-btn
            class="mb-3 disable-events"
            color="transparent"
            elevation="0"
          >Inspection</v-btn>
          <v-data-table
            height="250"
            :loading="get_data_load"
            :headers="header1[$store.getters.getPipeType]"
            :items="filterInspection"
            :items-per-page="5"
            class="elevation-1 custom-table"
          >
            <template v-slot:item.Order_No="{ item }">
            <v-btn @click="goToInspHistory(item)" light color="white" outlined small>{{item.Order_No}}</v-btn>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn v-if="item.Equipment === 'Drill Pipe'" dark x-small text @click="$getPDFglobal($store.getters.getPipeType,item,[{'Tubular':'/getdpPDF'},'Order_No'])">
                <v-icon>description</v-icon>
              </v-btn>
              <v-btn v-else-if="item.Equipment === 'Heavy Weight'" dark x-small text @click="$getPDFglobal($store.getters.getPipeType,item,[{'Tubular':'/gethwPDF'},'Order_No'])">
                <v-icon>description</v-icon>
              </v-btn>
              <v-btn v-else-if="item.Equipment === 'Drill Collar'" dark x-small text @click="$getPDFglobal($store.getters.getPipeType,item,[{'Tubular':'/getdcPDF'},'Order_No'])">
                <v-icon>description</v-icon>
              </v-btn>
              <v-btn v-else-if="$store.getters.getPipeType === 'BHA'" dark x-small text :to="'/inspectionBHA?q='+String(item.Order_No)" @click="getPDF($store.getters.getPipeType,item,[])">
                <v-icon>description</v-icon>
              </v-btn>
              <v-btn v-else dark x-small text @click="$getPDFglobal('Tubular',item,[{'Tubular':'/getoctgPDF'},'Order_No'])">
                <v-icon>description</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.action1="{ item }">
              <v-btn v-if="item.Equipment === 'Drill Pipe'" dark x-small text @click="$getEXCELglobal($store.getters.getPipeType,item,[{'Tubular':'/getdpExcel'},'Order_No'])">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
              <v-btn v-else-if="item.Equipment === 'Heavy Weight'" dark x-small text @click="$getEXCELglobal($store.getters.getPipeType,item,[{'Tubular':'/gethwExcel'},'Order_No'])">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
              <v-btn v-else-if="item.Equipment === 'Drill Collar'" dark x-small text @click="$getEXCELglobal($store.getters.getPipeType,item,[{'Tubular':'/getdcExcel'},'Order_No'])">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.Return_Date ="{ item }">
              <span>{{ item.Date_Formatted }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="px-10" outlined tile color="transparent" dark>
          <v-btn
            class="mb-3 disable-events"
            color="transparent"
            elevation="0"
          >Machining</v-btn>
          <v-data-table
            height="250"
            :loading="get_data_load"
            :headers="header2[$store.getters.getPipeType]"
            :items="filterMachining"
            :items-per-page="5"
            class="elevation-1 custom-table"
          >
          <template v-slot:item.action="{ item }">
              <v-btn
                v-if="item.Equipment === 'Drill Pipe'"
                dark
                x-small
                text
                @click="$getEXCELglobal($store.getters.getPipeType,item,[{'Tubular':'/getMachExcel'},'Order_No'])"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
              <v-btn
                v-else-if="item.Equipment === 'Heavy Weight'"
                dark
                x-small
                text
                @click="$getEXCELglobal($store.getters.getPipeType,item,[{'Tubular':'/getMachExcelHW'},'Order_No'])"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
              <v-btn
                v-else-if="item.Equipment === 'Drill Collar'"
                dark
                x-small
                text
                @click="$getEXCELglobal($store.getters.getPipeType,item,[{'Tubular':'/getMachExcelDC'},'Order_No'])"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
              <v-btn
                v-else-if="$store.getters.getPipeType === 'BHA'"
                dark
                x-small
                text
                @click="getPDF($store.getters.getPipeType,item,[])"
              >
                <v-icon>description</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.Return_Date ="{ item }">
              <span>{{ item.Date_Formatted }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col v-if="$store.getters.getPipeType==='Tubular'" cols="6">
        <v-card class="px-10" outlined tile color="transparent" dark>
          <v-btn
            class="mb-3 disable-events"
            color="transparent"
            elevation="0"
          >Hardbanding</v-btn>
          <v-data-table
            :loading="get_data_load"
            height="250"
            :headers="header2['Tubular']"
            :items="filterHardbanding"
            :items-per-page="5"
            class="elevation-1 custom-table"
          >
            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="item.Equipment === 'Drill Pipe'"
                dark
                x-small
                text
                @click="$getEXCELglobal($store.getters.getPipeType,item,[{'Tubular':'/getHardExcel'},'Order_No'])"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
              <v-btn
                v-else-if="item.Equipment === 'Heavy Weight'"
                dark
                x-small
                text
                @click="$getEXCELglobal($store.getters.getPipeType,item,[{'Tubular':'/getHardExcelHW'},'Order_No'])"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.Return_Date ="{ item }">
              <span>{{ item.Date_Formatted }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import axios from 'axios'
// import pdf_menu from '@/components/order_pdf_menu.vue'
import bha_pdf_menu from '@/components/BHA_order_pdf_menu.vue'
// import localMixin from '..mixins/local_functions.js'
export default {
  name: 'completedorders',
  // mixins: [localMixin],
  components: { bha_pdf_menu },
  props: {
    source: String
  },
  data () {
    return {
      commonload: false,
      header1: {
        Tubular: [
          {
            text: 'Order nr',
            align: 'start',
            sortable: true,
            value: 'Order_No'
          },
          { text: 'Inspection Date', value: 'Return_Date' },
          { text: 'Item no', value: 'Item_No' },
          { text: 'Equipment', value: 'Equipment' },
          { text: 'PO Number', value: 'Ref' },
          { text: 'Qty', value: 'QTY_Recived' },
          { text: 'PDF', value: 'action', sortable: false },
          { text: 'Excel', value: 'action1', sortable: false }
        ],
        BHA: [
          { text: 'Order', align: 'start', sortable: true, value: 'Order_No' },
          { text: 'Return Date', value: 'Return_Date' },
          { text: 'Returned From', value: 'Returned_From' },
          { text: 'Customer rep', value: 'Customer_Rep' },
          { text: 'Ref', value: 'Ref' },
          // { text: 'Completed', value: 'Check_Inspection' },
          { text: 'PO', value: 'PO' },
          { text: 'QTY', value: 'QTY' },
          { text: 'PDF', value: 'action', sortable: false }
        ]
      },
      header_order: {
        Tubular: [
          {
            text: 'Order nr',
            align: 'start',
            sortable: true,
            value: 'Order_No'
          },
          { text: 'Inspection date', value: 'Return_Date' },
          { text: 'Item no', value: 'Item_No' },
          { text: 'Equipment', value: 'Equipment' },
          { text: 'PO Number', value: 'Ref' },
          { text: 'Qty', value: 'QTY_Recived' },
          { text: 'PDF', value: 'action', sortable: false }
        ],
        BHA: [
          { text: 'Order', align: 'start', sortable: true, value: 'Order_No' },
          { text: 'Return Date', value: 'Return_Date' },
          { text: 'Returned From', value: 'Returned_From' },
          { text: 'Customer rep', value: 'Customer_Rep' },
          { text: 'Ref', value: 'Ref' },
          // { text: "Completed", value: "Check_Job" },
          { text: 'PO', value: 'PO' },
          { text: 'QTY', value: 'QTY' },
          { text: 'PDF', value: 'action', sortable: false }
        ]
      },
      header2: {
        Tubular: [
          {
            text: 'Order nr',
            align: 'start',
            sortable: true,
            value: 'Order_No'
          },
          { text: 'Inspection date', value: 'Return_Date' },
          { text: 'Item no', value: 'Item_No' },
          { text: 'Equipment', value: 'Equipment' },
          { text: 'PO Number', value: 'Ref' },
          { text: 'Excel', value: 'action', sortable: false }
        ],
        BHA: [
          { text: 'Order', align: 'start', sortable: true, value: 'Order_No' },
          { text: 'Return Date', value: 'Return_Date' },
          { text: 'Returned From', value: 'Returned_From' },
          { text: 'Customer rep', value: 'Customer_Rep' },
          { text: 'Ref', value: 'Ref' },
          // { text: 'Completed', value: 'Check_Machining' },
          { text: 'PO', value: 'PO' },
          { text: 'QTY', value: 'QTY' },
          { text: 'PDF', value: 'action', sortable: false }
        ]
      },
      pdfdialogO: false,
      bhaDialogO: false,
      orders: {
        Tubular: [], // machining uses this data too
        BHA: []
      },
      currentrec: '',
      currentOrdernr: '',
      currentCOCHB: '',
      currentCOCMC: '',
      eq: '',
      dialog: false,
      itemWithProperties: ''
    }
  },
  methods: {
    getPDF (type, item, pdf_params) {
      this.pdfdialogO = true
      this.currentOrdernr = item.Order_No
      this.currentrec = item.Receive_No
      this.itemWithProperties = item
      if (type === 'Tubular') {
        this.bhaDialogO = false
        this.currentCOCHB = item.COC_Hardbanding
        this.currentCOCMC = item.COC_Machining
        this.eq = item.Equipment
      } else if (type === 'BHA') {
        this.bhaDialogO = true
      }
    },
    goToInspHistory(item){
      window.location = "/inspection-view/"
    }
  },
  mounted () {
    // this.$initialDataLoad(this.$route.meta.backendRoutes,{'username':this.$store.getters.getUsername})
    this.set_data_load(true)
    let tubularData = null
    let bhaData = null
    axios
      .get('/getOrderconfirm', {
        params: { username: this.$store.getters.getUsername }
      })
      .then(response => {
        tubularData = this.$formatData(response.data, ['Return_Date']).filter(items => {
          return !this.getExcludedAssets.includes(items.Asset)
        })
        // tubularData = response.data
      })
      .then(() => {
        axios
          .get('/getOrderconfirmBHA', {
            params: { username: this.$store.getters.getUsername }
          })
          .then(response => {
            bhaData = this.$formatData(response.data, ['Return_Date'])
          })
          .finally(() => {    
            this.orders.BHA = bhaData      
            this.orders.Tubular = tubularData
            this.set_data_load(false)
          })
      })
  },
  computed: {
    ...mapGetters([
      'getEmail',
      'getUsername',
      'getAssetlist',
      'getAsset',
      'getFleet',
      'getPipeType',
      'getExcludedAssets'
    ]),
    isAssetList () {
      return this.getAssetlist.length > 0;
    },
    filterOrders () {
      if (this.orders[this.getPipeType]) {
        if (this.getFleet === true) {
          // Return global mixin filter function
          return this.orders[this.getPipeType].filter(order => {
            return order.Completed === 'Yes'
          })
        } else {
          // Filter the return of global filter function on Asset.
          return this.orders[this.getPipeType].filter(order => {
            return order.Completed === 'Yes' && order.Asset === this.getAsset
          })
        }
      }
    },
    filterInspection () {
      if (this.orders[this.getPipeType]) {
        if (this.getFleet === true) {
          return this.orders[this.getPipeType].filter(order => {
            return order.Check_Inspection === 'Yes'
          })
        } else {
          return this.orders[this.getPipeType].filter(order => {
            return (
              order.Check_Inspection === 'Yes' && order.Asset === this.getAsset
            )
          })
        }
      }
    },
    filterMachining () {
      if (this.orders[this.getPipeType]) {
        if (this.getFleet === true) {
          return this.orders[this.getPipeType].filter(order => {
            return order.Check_Machining === 'Yes'
          })
        } else {
          return this.orders[this.getPipeType].filter(order => {
            return (
              order.Check_Machining === 'Yes' && order.Asset === this.getAsset
            )
          })
        }
      }
    },
    filterHardbanding () {
      if (this.orders[this.getPipeType]) {
        if (this.getFleet === true) {
          return this.orders.Tubular.filter(order => {
            return order.Check_Hardbanding === 'Yes'
          })
        } else {
          return this.orders.Tubular.filter(order => {
            return (
              order.Check_Hardbanding === 'Yes' && order.Asset === this.getAsset
            )
          })
        }
      }
    }
  }
}
</script>

<style lang="css" scoped>
::v-deep table {
  margin: auto;
}
::v-deep .tdclass {
  border: 1px solid black;
  padding: 1px;
}

::v-deep .v-data-table-header th {
  white-space: nowrap;
}

::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

::v-deep .v-input__slot::before {
  border-style: none !important;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: #1C355E;
}

::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #1C355E !important;
}

::v-deep .custom-table thead th{
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child{
  border-radius: 10px 0 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

::v-deep .disable-events {
  pointer-events: none
}

</style>
