<template>
  <v-container class="mt-7">
    <!-- Stack the columns on mobile by making one full-width and the other half-width -->
    <v-row align="center" justify="center" class="justify-center align-center" style="height: 70px">
      <v-col cols="12" md="12">
        <v-card color="transparent" elevation="0" tile dark>
          <v-card-title class="align-center justify-center text-h4"><b>{{ getUsername }}</b></v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="justify-center align-center">
      <v-col cols="2" class="d-flex justify-center">
          <v-select
            dark
            v-if="isAssetList"
            :placeholder="currentAsset"
            v-model="select"
            :items="filteredAssets"
            label=""
            persistent-hint
            return-object
            single-line
            dense
          >
          </v-select>
      </v-col>
    </v-row>
    <!-- Columns are always 50% wide, on mobile and desktop -->
    <v-row>
      <v-col v-for="(card, index) in cards1" :key="`card-${index}`" cols="6">
        <v-hover v-slot="{ hover }">
          <div class="home-container" @click="getRoutePath(card.path)">
            <h1 class="home-title">{{ card.name }}</h1>
            <div class="home-overlay"></div>
            <v-card :class="{'home-hover': hover}" class="home-img" :color="card.color"></v-card>
          </div>
        </v-hover>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import inventory_img from '@/assets/inventory_compressed.jpg'
import shipping_img from '@/assets/shipping_compressed.jpg'
import ongoing_img from '@/assets/ongoing_compressed.jpg'
import completed_img from '@/assets/completed_compressed.jpg'
import qhse from '@/assets/qhse_compressed.jpg'
import statistikk from '@/assets/statistikk_compressed.jpg'

export default {
  name: 'Home',
  props: {
    source: String
  },
  data () {
    return {
      select: '',
      drawer: false,
      cards1: [
        {
          name: 'Inventory',
          img: inventory_img,
          pos: '50% 50%',
          color: '#3b7cbf',
          path: '/inventory',
          has_loaded: false
        },
        {
          name: 'Shipping',
          img: shipping_img,
          pos: '50% 50%',
          color: '#7aced4',
          path: '/delivery',
          has_loaded: false
        },
        {
          name: 'Ongoing Orders',
          img: ongoing_img,
          pos: '50% 50%',
          color: '#d9dfdf',
          path: 'ongoingorders',
          has_loaded: false
        },
        {
          name: 'Completed Orders',
          img: completed_img,
          pos: '50% 100%',
          color: '#3b7cbf',
          path: 'completedorders',
          has_loaded: false
        },
        {
          name: 'Statistics',
          img: statistikk,
          pos: '50% 80%',
          color: '#7aced4',
          path: 'statistics',
          has_loaded: false
        },
        {
          name: 'QHSE',
          img: qhse,
          pos: '50% 90%',
          color: '#d9dfdf',
          path: 'NCR',
          has_loaded: false
        }
      ]
    }
  },
  watch: {
    select: function () {
      if (this.select === 'Fleet Inventory') {
        this.setasset({ asset: this.select })
        this.setfleet({ fleet: true })
      } else {
        this.setasset({ asset: this.select })
        this.setfleet({ fleet: false })
      }
    }
  },
  methods: {
    ...mapActions(['setasset', 'setfleet']),
    getRoutePath (path) {
      this.$router.push(path)
    }
  },
  mounted () {
    // if (this.isAssetList === true) {
    //   this.items = this.getAssetlist.push('Fleet Inventory')
    // } else {
    //   this.items = this.getAssetlist
    // }
  },
  computed: {
    ...mapGetters(['getEmail', 'getUsername', 'getAsset', 'getAssetlist']),
    isAssetList () {
      if (this.getAssetlist.length > 0) {
        return true
      } else {
        return false
      }
    },
    filteredAssets () {
      return this.getAssetlist.sort().filter(item => {
        return item != this.getAsset
      })
    },
    currentAsset () {
      return this.getAsset
    },
    load_complete () {
      for (var i = 0; i <= 4; i++) {
        var currentCard = this.cards1[i]
        if (currentCard.has_loaded === false) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="css" scoped>
.home-container {
  position: relative;
  text-align: center;
  color: white;
  cursor: pointer;
  overflow: hidden;
  height: 200px;
}

.home-img {
  z-index: 1;
  filter: blur(3px);
  width: 100%;
  height: 100%;
}

.home-hover {
  filter: blur(0px) !important;
}

.home-title {
  left: 0;
  top: 45%;
  position:absolute;
  text-align:center;
  width: 100%;
  z-index: 3;
}

.home-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #1C355E;
  opacity: 0.60;
  z-index: 2;
}

::v-deep .v-select.v-text-field input {
  text-align: center;
}
</style>

<style>
.theme--light.v-list {
  text-align: start !important;
}

.v-text-field.v-input--dense {
  max-width: 200px !important;
}
</style>
