<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: ["data", "options"],
  methods: {
    renderBarChart () {
      this.renderChart(this.chartdata, this.options)
    }
  },
  computed: {
    chartdata: function() {
      return this.data;
    }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options)
  },
  watch: {
    data () {
      this._chart.destroy()
      this.renderBarChart()
    },
    deep: true
  }
};
</script>