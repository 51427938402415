const state = {
  home_image_loader: false,
  data_load: false,
  error: false,
  dialog_load: false
}

const getters = {
  get_home_image_loader: state => state.home_image_loader,
  get_data_load: state => state.data_load,
  get_error_state: state => state.error,
  get_dialog_load: state => state.dialog_load

}

const actions = {
  set_home_image_loader ({ commit }) {
    commit('home_image_load_finish')
  },
  set_data_load ({ commit }, value) {
    commit('data_load', value)
  },
  set_error_state ({ commit }, value) {
    commit('error_state', value)
  },
  set_dialog_load ({ commit }, value) {
    commit('dialog_load', value)
  }
}

const mutations = {
  home_image_load_finish: (state) => {
    state.home_image_loader = true
  },
  data_load: (state, value) => {
    state.data_load = value
  },
  error_state: (state, value) => {
    state.error = value
  },
  dialog_load: (state, value) => {
    state.dialog_load = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
