<template>
  <v-card dark color="transparent" width="95%" class="mx-auto mt-10" elevation="0">
    <v-card-title class="text-h5">{{ displayname }}</v-card-title>
    <v-container fluid class="d-flex justify-center"
    >
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        clearable
        :label="search_text"
        hide-details
      ></v-text-field>
      <v-spacer/>
      <v-checkbox
        v-if="options"
        class="d-inline mx-4"
        v-model="selected"
        label="Completed"
        value="completed"
      ></v-checkbox>
      <v-checkbox
        v-if="options"
        class="d-inline mx-4"
        v-model="selected"
        label="Ongoing"
        value="ongoing"
      ></v-checkbox>
      <v-select
        v-model="selected_columns"
        placeholder="Filter columns"
        :items="headers"
        multiple
        class="temp"
        v-if="selected_columns.length > 0"
      >
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">Selected columns: {{ selected_columns.length }}</span>
        </template>
      </v-select>
    </v-container>
    <v-data-table :loading="loader" :headers="headers" :items="table_items" :search="search" :item-class="'orange'" class="elevation-1 custom-table" :sort-by="defaultSort" :sort-desc="true">
      <template v-slot:item.action="{ item }">
        <v-btn x-small text @click="getPDF_func(type, item, pdf_params)">
          <v-icon>description</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.Inspection_Date ="{ item }">
        <td :style="{backgroundColor: date_colorizer(item.Date_Formatted)}">
          <span>{{ item.Date_Formatted }}</span>
        </td>
      </template>
      <template v-slot:item.Return_Date ="{ item }">
        <span>{{ item.Date_Formatted }}</span>
      </template>
      <template v-slot:item.Returned_Date ="{ item }">
        <span>{{ item.Date_Formatted }}</span>
      </template>
      <template v-slot:item.Date ="{ item }">
        <span>{{ item.Date_Formatted }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
var moment = require('moment')
moment().format()
export default {
  name: 'data_table',
  props: [
    'displayname',
    'search_text',
    'options',
    'getPDF_func',
    'loader',
    'headers',
    'items',
    'pdf_params',
    'type',
    'defaultSort'
  ],
  data () {
    return {
      selected: ['completed', 'ongoing'],
      ODP_data: this.items,
      selected_columns: [],
      search: ''
    }
  },
  methods: {
    date_colorizer(insp_date){
      if (this.getUsername === "Innovar Solution AS"){
        var insp = moment(insp_date,"DD.MM.YYYY")
        var now = moment(moment().format("DD.MM.YYYY"),"DD.MM.YYYY")
        var n_months = now.diff(insp,'months')
        if (n_months < 5){
          return "green"
        }else if (n_months >= 5 && n_months <= 6){
          return "orange"
        }
        return "red"
      }
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getAsset', 'getFleet', 'getPipeType', 'getExcludedAssets']),
    table_items () {
      if (this.options) {
        return this.filteredData
      } else {
        return this.items.filter(order => {
          return !this.getExcludedAssets.includes(order.Asset)
        })
      }
    },
    filteredData () { // Filters data using 'completed' and 'ongoing'.'
      if (this.ODP_data[this.getPipeType]) {
        if (this.getFleet === true) {
          if (this.selected.length === 2) {
            
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return !this.getExcludedAssets.includes(order.Asset)
            })

          } else if (this.selected.includes('completed')) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed === 'Yes' && !this.getExcludedAssets.includes(order.Asset)
            })
          } else if (this.selected.includes('ongoing')) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed !== 'Yes' && !this.getExcludedAssets.includes(order.Asset)
            })
          }
        } else {
          if (this.selected.length === 2) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Asset === this.getAsset
            })
            // eslint-disable-next-line eqeqeq
          } else if (this.selected.includes('completed')) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed === 'Yes' && order.Asset === this.getAsset
            })
          } else if (this.selected.includes('ongoing')) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed === 'No' && order.Asset === this.getAsset
            })
          }
        }
      }
    },

  }
}
</script>

<style scoped>

::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

.v-input.v-text-field.v-select {
  max-width: 300px;
}

::v-deep .v-input__slot::before {
  border-style: none !important;
}

.v-menu__content {
  text-align: start !important;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: #1C355E;
}

::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #1C355E !important;
}

::v-deep .custom-table thead th{
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child{
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child{
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

</style>
