<template>
  <container fluid >
    <div style="text-align: left;" class= "display-2 font-weight-thick" color="white">test</div>
    
  </container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import firebase from 'firebase'
export default {
  name: 'racks',
  data () {
    return {
      email: '',
      password: '',
      load: false,
      snackbar: false,
      item: 1,
      items: [
        { text: 'Site', value: 'Kverneland' },
        { text: 'Site Location', value: 'Rig_Ready' },
        { text: 'Rack ID', value: '01A' },
        { text: 'Item Number', value: 'OWS278DP.01' },
        { text: 'Description', value: 'N/A' },
        { text: 'Maxrowcount', value: '20' }
      ]
    }
  },
  methods: {
    clearFields (err) {
      this.password = ''
      this.load = false
      this.snackbar = true
      this.text = 'Oops.. ' + err.message
    },
    ...mapActions(['fetchEmail', 'fetchUsername']),
    login: function () {
      this.load = true
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
        (user) => {
          this.fetchEmail()
          this.fetchUsername({ usrname: this.email })
          this.$router.replace('home')
        },
        (err) => {
          this.clearFields(err)
        }
      )
    }
  },
  computed: {
    ...mapGetters([
      'getEmail'
    ]),
    allItems () {
      return this.items
    }
  }
}
</script>

<style lang="css" scoped>

</style>
