var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto mt-10",attrs:{"dark":"","color":"transparent","width":"95%","elevation":"0"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.displayname))]),_c('v-container',{staticClass:"d-flex justify-center",attrs:{"fluid":""}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","clearable":"","label":_vm.search_text,"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.options)?_c('v-checkbox',{staticClass:"d-inline mx-4",attrs:{"label":"Completed","value":"completed"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.options)?_c('v-checkbox',{staticClass:"d-inline mx-4",attrs:{"label":"Ongoing","value":"ongoing"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.selected_columns.length > 0)?_c('v-select',{staticClass:"temp",attrs:{"placeholder":"Filter columns","items":_vm.headers,"multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v("Selected columns: "+_vm._s(_vm.selected_columns.length))]):_vm._e()]}}],null,false,4176047382),model:{value:(_vm.selected_columns),callback:function ($$v) {_vm.selected_columns=$$v},expression:"selected_columns"}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"loading":_vm.loader,"headers":_vm.headers,"items":_vm.table_items,"search":_vm.search,"item-class":'orange',"sort-by":_vm.defaultSort,"sort-desc":true},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.getPDF_func(_vm.type, item, _vm.pdf_params)}}},[_c('v-icon',[_vm._v("description")])],1)]}},{key:"item.Inspection_Date",fn:function(ref){
var item = ref.item;
return [_c('td',{style:({backgroundColor: _vm.date_colorizer(item.Date_Formatted)})},[_c('span',[_vm._v(_vm._s(item.Date_Formatted))])])]}},{key:"item.Return_Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))])]}},{key:"item.Returned_Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))])]}},{key:"item.Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }