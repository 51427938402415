<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card dark color="#1C355E" class="justify-center align-center">
      <v-toolbar dense color="transparent" elevation="0">
        <v-btn icon @click="clearList(dialog)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
        ><b>{{ Itemnr }}</b></v-toolbar-title
        >
      </v-toolbar>
      <v-card color="transparent" width="95%" class="mx-auto mt-5" elevation="0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-icon @click="getExcel(Itemnr, location)" v-if="checkLocation()">mdi-microsoft-excel</v-icon>
          <v-icon @click="getExcelShippedSerials(Itemnr)" v-if="location === 'Shipped'">mdi-microsoft-excel</v-icon>
        </v-card-title>
        <v-data-table
          class="custom-table"
          :loading="loader"
          :headers="compute_headers"
          :items="items"
          :search="search"
        >
          <template v-slot:item.Serial_Number="{ item }" v-if="location !== 'Shipped'">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  width="90"
                  v-on="on"
                  small
                  light
                  color="white"
                  @click="showRack(item)"
                >{{ item.Serial_Number }}<v-spacer></v-spacer
                ></v-btn>
              </template>
              <span>Show Rack</span>
            </v-tooltip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn x-small text @click="getDTwithAttachmentPDF(item)">
              <v-icon>description</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.DT_No="{ item }" v-if="location !== 'Booked DT' && location !== 'Shipped'">
            <v-tooltip right>
              <template v-slot:activator="{ on }" >
                <v-btn
                  v-on="on"
                  small
                  light
                  color="white"
                  @click="serialFunction(item)"
                >{{ item.DT_No }}<v-spacer></v-spacer
                ></v-btn>
              </template>
              <span>Show S/N</span>
            </v-tooltip>
          </template>
          <template v-slot:item.Rig="{ item }" v-if="location === 'Shipped' && !Array.isArray(currentItem)">
              <span>{{ currentItem.Rig }}</span>
          </template>
          <template v-slot:item.Shipped_Date="{ item }" v-if="location === 'Shipped' && !Array.isArray(currentItem)">
              <span>{{ currentItem.Date_Formatted }}</span>
          </template>
          <template v-slot:item.Customer_Name="{ item }" v-if="location === 'Shipped' && !Array.isArray(currentItem)">
              <span>{{ currentItem.Customer_Name }}</span>
          </template>
          <template v-slot:item.Shipped="{ item }">
              <span>{{ item.Quantity }}</span>
          </template>
          <template slot="body.append" v-if="location === 'Shipped DT'">
            <tr>
              <th>Total</th>
              <th> </th>
              <th> </th>
              <th> </th>
              <th>{{ totalQuantity }}</th>
              <th @click="serialFunction2(Itemnr)" style="cursor: pointer;">{{ totalOffshore }}</th>
              <th>{{ totalReturned }}</th>
              <th> </th>
              <th> </th>

            </tr>
          </template>
        </v-data-table>
      </v-card>

    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  name: 'pipe_dialog',
  props: [
    'dialog',
    'Itemnr',
    'search',
    'loader',
    'headers',
    'items',
    'showRack',
    'clearList',
    'location',
    'serialFunction',
    'serialFunction2',
    'currentItem'
  ],
  methods: {
    // TODO: fiks logikk
    checkLocation() {
      if ((this.location === 'Shipped DT') || (this.location === 'Shipped')) {
        return false
      }
      return true
    },
    getDTwithAttachmentPDF(item) {
      axios
        .get('/getDTattachmentPDF', {
          params: { dt_no: item.DT_No, item_no: item.Item_No }
        })
        .then(response => {
          window.open(response.data.pdflink, '_blank')
        })
    },
    getExcelShippedSerials (Itemnr) {
      axios
        .get('/createExcelShippedSerials', {
          params: { itemnr: Itemnr }
        })
        .then(response => {
          this.JSONToCSVConvertor(response.data, 'ExcelTally', true)
        })
    },
    getExcel (Itemnr, location) {
      axios
        .get('/createExcel', {
          params: { itemnr: Itemnr, location: location }
        })
        .then(response => {
          this.JSONToCSVConvertor(response.data, 'ExcelTally', true)
        })
    },
    JSONToCSVConvertor (JSONData, ReportTitle, ShowLabel) {
    // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    // Filter out JSONData according to this.search (search bar).
    // console.log(JSONData)
      JSONData = JSONData.filter(row => {
        return row['Serial Number'].toLowerCase().indexOf(this.search.toLowerCase()) === 0
      })
      var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData

      var CSV = 'sep=,' + '\r\n\n'

      // This condition will generate the Label/Header
      if (ShowLabel) {
        var row = ''

        // This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {
          // Now convert each value to string and comma-seprated
          row += index + ','
        }

        row = row.slice(0, -1)

        // append Label row with line break
        CSV += row + '\r\n'
      }

      // 1st loop is to extract each row
      for (var i = 0; i < arrData.length; i++) {
        var row = ''

        // 2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
          // console.log(String(arrData[i][index]).replace(",","."))
          row += '="' + String(arrData[i][index]).replace(',', '.').replace('"', '') + '",'
        }
        // console.log(row)
        row.slice(0, row.length - 1)

        // add a line break after each row
        CSV += row + '\r\n'
      }

      if (CSV == '') {
        alert('Invalid data')
        return
      }

      // Generate a file name
      var fileName = ReportTitle
      // //this will remove the blank-spaces from the title and replace it with an underscore
      // fileName += ReportTitle.replace(/ /g,"_");

      // Initialize file format you want csv or xls
      var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)

      // Now the little tricky part.
      // you can use either>> window.open(uri);
      // but this will not work in some browsers
      // or you will not get the correct file extension

      // this trick will generate a temp <a /> tag
      var link = document.createElement('a')
      link.href = uri

      // set the visibility hidden so it will not effect on your web-layout
      link.style = 'visibility:hidden'
      link.download = fileName + '.csv'

      // this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  },
  computed: {
    totalQuantity() {
      return this.items.reduce((n, {Quantity}) => n + Quantity, 0)
    },
    totalOffshore() {
      return this.items.reduce((n, {Offshore}) => n + Number(Offshore), 0)
    },
    totalReturned() {
      return this.items.reduce((n, {Returned}) => n + Number(Returned), 0)
    },
    compute_headers() {
      // exclude column in table based on selected location
      var shipped_headers = ["Item_No", "DT_No", "Rig", "Date_Formatted", "Quantity", "Customer_Name", "Offshore", "Returned", "action"]
      var shipped_serial_headers = ["Item_No", "DT_No", "Rig", "Date_Formatted", "Serial_Number", "Date_Formatted", "Current_Location", "Shipped_Date", "Customer_Name"]
      var booked_headers = ["DT_No", "Customer_Name", "Rig", "Quantity", "Ref"]
      var rigready_headers = ["Serial_Number", "KWP_Length", "Rack_Position", "Position", "Current_Location", "TC_TBA", "Date_Formatted"]
      var machining_headers = ["Serial_Number", "KWP_Length", "Rack_Position", "Position", "Current_Location", "Date_Formatted", "Completion_Date", "Current_Location"]
      var other_headers = ["Serial_Number", "KWP_Length", "Date_Formatted", "Completion_Date", "Current_Location"]
      var ipc_headers = ["Serial_Number", "KWP_Length", "Date_Formatted"]
      if (this.location === "Shipped DT") {
        return this.headers.filter((e) => shipped_headers.includes(e.value)); 
      } else if (this.location === "Booked DT") {
        return this.headers.filter((e) => booked_headers.includes(e.value)); 
      } else if ((this.location === "Rig Ready") || (this.location === "VTI")) {
        return this.headers.filter((e) => rigready_headers.includes(e.value));
      } else if (this.location === "Shipped") {
        return this.headers.filter((e) => shipped_serial_headers.includes(e.value));
      } else if (this.location === "Machining") {
        return this.headers.filter((e) => machining_headers.includes(e.value));
      } else if (this.location === "IPC") {
        return this.headers.filter((e) => ipc_headers.includes(e.value));
      } else {
        return this.headers.filter((e) => other_headers.includes(e.value));
      }
    }
  }
}
</script>

<style scoped>
::v-deep .tooltippen {
  text-align: left;
}

::v-deep table {
  margin: auto;
}
::v-deep .tdclass {
  border: 1px solid black;
  padding: 1px;
}

::v-deep .v-data-table-header th {
  white-space: nowrap;
}

::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

.v-input.v-text-field.v-select {
  max-width: 300px;
}

::v-deep .v-input__slot::before {
  border-style: none !important;
}

.v-menu__content {
  text-align: start !important;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: #1C355E;
}

::v-deep .custom-table thead th{
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child{
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child{
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #1C355E !important;
}

</style>
