<template>
  <v-app>
    <navbar/>
    <v-main :class="{'pa-0': $route.path === '/login'}" style="background-color: #1C355E">
      <router-view/>
      <v-snackbar v-model="test" :color="snackbarColor" timeout="5000">
        {{ snackbarText }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import navbar from './views/Navbar.vue'
import { mapGetters } from 'vuex'
import bottombar from './views/Bottombar.vue'
import RotateLoader from 'vue-spinner/src/RotateLoader.vue'

export default {
  name: 'app',
  components: { navbar, bottombar, RotateLoader },
  data () {
    return {
      snackbarColor: 'red',
      snackbarText: 'Error: The requested document was not found. Please try again later...',
      test: false
    }
  },
  watch: {
    errorState: function (newVal) {
      if (newVal === true) {
        this.test = true
        this.$store.dispatch('set_error_state', false)
      }
    }
  },
  computed: {
    ...mapGetters(['getLoadingcomplete', 'get_error_state']),
    errorState () {
      return this.get_error_state
    }
  }
}
</script>

<style>
#app {
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

</style>
