<template>
<v-card dark color="transparent" width="95%" class="mx-auto mt-10" elevation="0">
  <v-dialog v-model="newUserDialog" max-width="900px" persistent>
    <v-card dark>
      <v-toolbar :color="toolbarColor">
        <v-spacer/>
        <v-toolbar-title>
          {{ userAction }}
        </v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="newUserForm" ref="newUserForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="new_name" label="Name" required :rules="fieldRules.required"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="new_email" label="E-mail" required :rules="fieldRules.emailfield"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="new_phone" label="Phone Number" required :rules="fieldRules.required"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="new_customer"
                  label="Customer"
                  :items="allCustomers"
                  item-text="Customer"
                  return-object
                  :rules="fieldRules.required"
                  :disabled="userAction==='Edit User'"
                  @change="clear_user_assets"
                  required>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="user_assets"
                  label="Included Assets"
                  :items="possible_included_assets"
                  item-text="ODP_Customer_Asset::Asset_name"
                  return-object
                  multiple
                  chips
                  persistent-hint
                  :disabled="new_customer.assetList < 1 || new_customer < 1"
                  hint="If empty, all assets are chosen"
                  >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="user_assets_excluded"
                  label="Excluded Assets"
                  :items="possible_excluded_assets"
                  item-text="ODP_Customer_Asset::Asset_name"
                  return-object
                  multiple
                  chips
                  persistent-hint
                  hint="Chosen assets are excluded from customer view"
                  >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="user_routes"
                  label="Routes"
                  :items="routesList"
                  return-object
                  multiple
                  chips
                  persistent-hint
                  hint="If empty, all routes are chosen"
                  >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeNewUserDialog" text color="error" plain :disabled="confirmBtnLoader">Cancel</v-btn>
        <v-btn v-if="userAction==='Create User'" @click="createNewUser" text color="primary" plain :disabled="!newUserForm" :loading="confirmBtnLoader">Confirm</v-btn>
        <v-btn v-else-if="userAction==='Edit User'" @click="editCurrentUser" text color="primary" plain :disabled="!newUserForm" :loading="confirmBtnLoader">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="deleteUserDialog" max-width="300px">
    <v-card dark>
      <v-card-text class="pt-5 text-left">
        {{ deleteUserText }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="deleteUserDialog=false" text color="error" plain :disabled="confirmBtnLoader">Cancel</v-btn>
        <v-btn text color="primary" plain @click="deleteCurrentUser" :loading="confirmBtnLoader">Confirm</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-card-title>
    User Overview

        <v-btn icon class="ml-5" @click="currentAction('')">
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
    <v-spacer></v-spacer>
    <v-text-field
      v-model="search"
      prepend-icon="mdi-magnify"
      label="Search (Name, Email etc..)"
      single-line
      hide-details
    ></v-text-field>
  </v-card-title>
  <v-data-table
    class="custom-table"
    :loading="get_data_load"
    :headers="headers"
    :items="allUsers"
    sort-by="last_login.timestamp"
    :sort-desc="sortDesc"
    :search="search">
    <template v-slot:item.action="{ item }">
      <v-btn icon small @click="currentAction(item)">
        <v-icon>mdi-account-edit</v-icon>
      </v-btn>
          <v-btn icon small @click="deleteUser(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
    </template>
    <template v-slot:item.last_login.timestamp="{ item }">
      <span @click="test(item)">{{ item.last_login.date }}</span>
    </template>
  </v-data-table>
</v-card>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'users',
  data () {
    return {
      sortDesc: true,
      newUserForm: false,
      deleteUserDialog: false,
      confirmBtnLoader: false,
      toolbarColor: '#1C355E',
      selectedUser: null,
      userAction: '',
      fieldRules: {
        required: [(v) => !!v || 'This field is required'],
        emailfield: [(v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(v).toLowerCase()) || 'E-mail must be valid']
      },
      search: '',
      allUsers: [],
      allCustomers: [],
      user_assets: [],
      user_assets_excluded: [],
      possible_included_assets: [],
      possible_excluded_assets: [],
      user_routes: [],
      user_old_routes: [],
      routesList :  [
        {text: 'Dril Pipe', value: 'dp'},
        {text: 'Heavy Weight', value: 'heavy'},
        {text: 'Dril Collar', value: 'dcollar'},
        {text: 'OCTG', value: 'octg'},
        {text: 'BHA', value: 'inspectionBHA'},
        {text: 'Non Conformance', value: 'NCR'},
      ],

      loader: false,
      new_name: '',
      new_email: '',
      userid: '',
      recordid: '',
      new_customer: { Customer: '', recordId: '' },
      new_phone: '',
      newUserDialog: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Phone Number', value: 'phone', sortable: false },
        { text: 'E-mail', value: 'email' },
        { text: 'Customer', value: 'customer' },
        { text: 'Last Login', value: 'last_login.timestamp' },
        { text: '', value: 'action', sortable: false }
      ]
    }
  },
  mounted () {
    this.set_data_load(true)
    axios.get('/getAllUsers')
      .then((res) => {
        this.allUsers = res.data.userList
        this.allCustomers = res.data.customerList
        console.log(res.data);
        this.set_data_load(false)
      })
  },
  computed: {
    ...mapGetters(['get_data_load']),
    deleteUserText () {
      if (this.selectedUser) {
        return 'Are you sure you want to delete ' + this.selectedUser.name + ' from ' + this.selectedUser.customer + '?'
      } else {
        return ''
      }
    }
  },
  watch: {
    user_assets() {
      var diff = this.new_customer.assetList.filter(({ recordId: id1 }) => !this.user_assets.some(({ recordId: id2 }) => id2 === id1));
      this.possible_excluded_assets = diff
    },
    user_assets_excluded() {
      var diff = this.new_customer.assetList.filter(({ recordId: id1 }) => !this.user_assets_excluded.some(({ recordId: id2 }) => id2 === id1));
      this.possible_included_assets = diff
    }
  },
  methods: {
    ...mapActions(['set_data_load']),
    clear_user_assets() {
      this.user_assets = []
      this.possible_included_assets = this.new_customer.assetList
      this.possible_excluded_assets = this.new_customer.assetList
    },
    deleteUser (item) {
      this.selectedUser = item
      this.deleteUserDialog = true
    },
    currentAction (item) {
      if (item) {
        this.user_old_routes = item.routesList.map(a => a.value ?? a)
        this.userAction = 'Edit User'
        this.newUserDialog = true
        this.selectedUser = item
        this.new_name = item.name
        this.new_phone = item.phone
        this.new_email = item.email
        this.new_customer = item.customer
        this.userid = item.userid
        this.recordid = item.recordid
        this.user_routes = item.routesList
      } else {
        this.userAction = 'Create User'
        this.newUserDialog = true
      }
    },
    closeNewUserDialog () {
      this.userAction = ''
      this.new_name = ''
      this.new_phone = ''
      this.new_email = ''
      this.new_customer = ''
      this.userid = ''
      this.recordid = ''
      this.user_assets = []
      this.toolbarColor = '#1C355E'
      this.$refs.newUserForm.resetValidation()
      this.newUserDialog = false
      this.user_routes = []
      this.user_old_routes = ''
    },
    deleteCurrentUser () {
      this.confirmBtnLoader = true
      axios.post('/deleteCurrentUser', this.selectedUser)
        .then(() => {
          this.confirmBtnLoader = false
          const idx = this.allUsers.map(e => e.userid).indexOf(this.selectedUser.userid)
          this.allUsers.splice(idx, 1)
          this.deleteUserDialog = false
        })
        .catch((err) => {
          this.confirmBtnLoader = false
          console.log(err)
        })
    },
    editCurrentUser () {
      this.confirmBtnLoader = true
      const values = this.user_routes.map(a => a.value ?? a)
      const added_routes = values.filter((x) => !this.user_old_routes.includes(x))
      const removed_routes = this.user_old_routes.filter((x) => !values.includes(x))
      axios.post('/editCurrentUser', {
        old_email: this.selectedUser.email,
        new_email: this.new_email,
        name: this.new_name,
        phone: this.new_phone,
        userid: this.userid,
        recordid: this.recordid,
        user_removed_routes: removed_routes,
        user_added_routes: added_routes,
        customer: this.new_customer
      })
        .then(() => {
          this.toolbarColor = 'success'
          this.confirmBtnLoader = false
          const idx = this.allUsers.map(e => e.userid).indexOf(this.userid)
          this.allUsers[idx].name = this.new_name
          this.allUsers[idx].phone = this.new_phone
          this.allUsers[idx].email = this.new_email
          this.allUsers[idx].routesList = this.user_routes
          this.closeNewUserDialog()
        })
        .catch((err) => {
          this.toolbarColor = 'error'
          this.confirmBtnLoader = false
          console.log(err)
        })
    },
    createNewUser () {
      this.confirmBtnLoader = true
      axios.post('/createNewUser', {
        name: this.new_name,
        email: this.new_email,
        phone: this.new_phone,
        customer: this.new_customer,
        user_assets: this.user_assets,
        user_assets_excluded: this.user_assets_excluded,
        user_routes: this.user_routes.map(a=>a.value)
      })
        .then((res) => {
          this.toolbarColor = 'success'
          this.confirmBtnLoader = false
          this.allUsers.push({
            name: this.new_name,
            email: this.new_email,
            phone: this.new_phone,
            customer: this.new_customer.Customer,
            userid: res.data.userid,
            recordid: res.data.recordid
          })
          this.closeNewUserDialog()
        })
        .catch((err) => {
          this.toolbarColor = 'error'
          this.confirmBtnLoader = false
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>
::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

.v-input.v-text-field.v-select {
  max-width: 300px;
}

.v-menu__content {
  text-align: start !important;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: #1C355E;
}

::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #1C355E !important;
}

::v-deep .custom-table thead th{
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child{
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child{
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

</style>
