<template>
  <v-container fluid>
  <v-card dark color="transparent" flat>
      <v-row>
          <v-col cols="4">
              <v-card-actions class="justify-center">
                  <v-btn @click="viewPDF('NTO')" color="transparent">NTO</v-btn>
              </v-card-actions>
          </v-col>
          <v-col cols="4">
              <v-card-actions class="justify-center">
                  <v-btn @click="viewPDF('ISO')" color="transparent">ISO 9001 | ISO 14001</v-btn>
              </v-card-actions>
          </v-col>
          <v-col cols="4">
              <v-card-actions class="justify-center">
                  <v-btn @click="viewPDF('JQS')" color="transparent">JQS</v-btn>
              </v-card-actions>
          </v-col>          
      </v-row>
  </v-card>

  <v-dialog v-model="pdfDialog">
      <iframe :src="pdfSource" type="application/pdf" height="1000 px"></iframe>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
    name: 'certificates',
    data() {
        return {
            pdfDialog: false,
            pdfSource: null,
            NTO: '',
            JQS: '',
            ISO: ''
        }
    },
    mounted() {
        axios
            .get('/getCertificates')
            .then(resp => {
                this.NTO = "data:application/pdf;base64,"+resp.data.NTO.slice(2, resp.data.NTO.length-1)
                this.JQS = "data:application/pdf;base64,"+resp.data.JQS.slice(2, resp.data.JQS.length-1)
                this.ISO = "data:application/pdf;base64,"+resp.data.ISO.slice(2, resp.data.ISO.length-1)
            })

    },
    methods: {
        viewPDF(name) {
            if (name === "NTO") {
                this.pdfSource = this.NTO
            } else if (name === "JQS") {
                this.pdfSource = this.JQS
            } else if (name === "ISO") {
                this.pdfSource = this.ISO
            }
            this.pdfDialog = true
        }
    }
}
</script>

<style>

</style>