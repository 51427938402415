<template>
  <v-app class="body">
    <div class="fullscreen-video-wrap">
      <video src="../assets/video.mp4" autoplay="true" loop="true"></video>
    </div>
    <div class="overlay"></div>
    <div class="content">
      <v-snackbar v-model="snackbar" :color="snackbarColor" timeout="5000">
        {{ text }}
      </v-snackbar>
    <v-container class="fill-height" fluid>
    <v-row align="center" justify="center" style="height: 95vh">
      <div class="text-center">
            <v-dialog v-model="dialog" width="400">
              <v-card dark>
                <v-toolbar color="#1C355E">
                  <v-toolbar-title>Change Password</v-toolbar-title>
                </v-toolbar>
                <v-form v-model="isFormValid" @submit.prevent="forgetPassword" ref="formRef">
                <v-card-text class="mt-5">
                  <v-text-field
                    class="ma-0 pa-0"
                    v-model="forgot_mail"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      type="submit"
                      color="primary"
                      text
                      :disabled="!isFormValid"
                    >
                      Confirm
                    </v-btn>
                </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
    </div>
    <v-col cols="12" sm="6" md="6" class=" d-flex flex-column align-center justify-center">
    <v-card class="elevation-12" color="transparent" elevation="0" width="450px">
        <v-form>
          <v-text-field
            dark
            label="Username"
            name="login"
            prepend-icon="person"
            type="text"
            v-model="email"
            :loading="load"
            v-on:keyup.enter="login"
          ></v-text-field>
          <v-text-field
            dark
            id="password"
            label="Password"
            name="password"
            prepend-icon="lock"
            type="password"
            v-model="password"
            :loading="load"
            v-on:keyup.enter="login"
          ></v-text-field>
        </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="transparent" @click="dialog = true" elevation="0"
          >Forgot Password?</v-btn
        >
        <v-btn dark color="transparent" @click="login" elevation="0">Login</v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</v-row>
</v-container>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import firebase from 'firebase'
import image from '../assets/logo_white.png'

export default {
  name: 'login',
  data () {
    return {
      videosrc: ['../assets/video.mp4'],
      email: '',
      password: '',
      snackbarColor: 'primary',
      load: false,
      snackbar: false,
      text: '',
      image: image,
      dialog: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      forgot_mail: '',
      isFormValid: false
    }
  },
  methods: {
    clearFields (err) {
      this.password = ''
      this.load = false
      this.snackbar = true
      this.text = 'Oops.. ' + err.message
      this.snackbarColor = 'error'
    },
    forgetPassword () {
      firebase
        .auth()
        .sendPasswordResetEmail(this.forgot_mail)
        .then(() => {
          this.snackbar = true
          this.text = 'Success'
          this.snackbarColor = 'success'
          this.dialog = false
          this.email = this.forgot_mail
          this.forgot_mail = ''
          this.password = ''
          this.$refs.formRef.reset()
        })
        .catch(error => {
          this.snackbar = true
          this.snackbarColor = 'error'
          this.text = 'Oops.. Check that your email is correct'
          this.forgot_mail = ''
          this.$refs.formRef.reset()
          this.email = ''
          this.password = ''
        })
    },
    ...mapActions(['fetchEmail', 'fetchUsername', 'setToken']),
    login: function () {
      this.load = true
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          user => {
            this.fetchEmail()
            this.fetchUsername({ usrname: this.email })
            firebase.auth().currentUser.getIdToken()
              .then((token) => {
                this.setToken(token)
              })
          },
          err => {
            this.clearFields(err)
          }
        )
    }
  },
  mounted () {
    window.addEventListener('keyup', event => {
      if (event.key === 'Enter') {
        this.login()
      }
    })
  },
  computed: {
    ...mapGetters(['getEmail', 'getAssetlist'])
  }
}
</script>

<style lang="css">
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-text-fill-color: white !important;
}

.body {
  margin: 0;
  overflow: hidden;
}

.fullscreen-video-wrap {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.fullscreen-video-wrap video {
  min-width: 100%;
  min-height: 100%;
}

.overlay {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background: #1C355E;
  opacity: 0.85;
  z-index: 1;
}

.content {
  z-index: 2;
}
</style>
