<template>
  <v-container fluid>
      <v-row>
          <v-col>
          <v-card dark color="transparent" flat ref="form">
              <v-card-title> 
              Inpsection overview
              </v-card-title>
              <v-card-text>
                  <v-autocomplete
                      ref="itemno"
                      label="Order no"
                      placeholder="Order no"
                  ></v-autocomplete>
                </v-card-text>
              </v-card>
          </v-col>
          <v-col>
              <v-card dark color="transparent" flat>
                  <v-card-text class="text-left">
                  </v-card-text>
              </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'inspectionstats',
  data() {
    
  },
}
</script>
