import firebase from 'firebase'
import axios from 'axios'
import router from '../../router/index.js'

const state = {
  email: '',
  username: '',
  loadingcomplete: false,
  assetlist: [],
  routeslist: [],
  excludedAssets: [],
  asset: null,
  fleet: false,
  token: null,
  isAdmin: false,
  pipeType: 'Tubular',
  customerList: []
}

const getters = {
  getEmail: state => state.email,
  getAuth: state => !!state.email,
  getUsername: state => state.username,
  getLoadingcomplete: state => state.loadingcomplete,
  getAssetlist: state => state.assetlist,
  getExcludedAssets: state => state.excludedAssets,
  getAsset: state => state.asset,
  getFleet: state => state.fleet,
  getToken: state => state.token,
  getIsAdmin: state => state.isAdmin,
  getPipeType: state => state.pipeType,
  getCustomerList: state => state.customerList,
  getRouteslist: state => state.routeslist
}

const actions = {
  loading ({ commit }, value) {
    commit('loading', value)
  },
  fetchEmail ({ commit }) {
    const email = firebase.auth().currentUser.email
    const pack = email
    commit('eMail', pack)
  },
  logOut ({ commit, dispatch }) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch('clearracksAndPipes')
        dispatch('clearAdminRacks')
        commit('setLoadingcomplete', false)
        commit('logout')
        commit('error_state', false)
        commit('dialog_load', false)
        commit('data_load', false)
        router.replace('login')
      })
  },
  fetchUsername ({ commit }, { usrname }) {
    let usrnameresponse = ''
    usrnameresponse = axios.post('/fetchUserName', { usrname })
    usrnameresponse.then(response => {
      commit('setUsername', response.data.username)
      commit('setAssetList', response.data.assetlist)
      commit('setExcludedAssets', response.data.excludedAssets)
      commit('setRoutesList', response.data.routeslist)
      commit('setIsAdmin', Boolean(response.data.isAdmin))
      if (response.data.isAdmin) {
        axios.get('/getAllUsers')
          .then((res) => {
            commit('setCustomerList', res.data.customerList)
            router.replace('admin')
            commit('setLoadingcomplete', true)
          })
      } else if (response.data.assetlist.includes('Fleet Inventory')){
        router.replace('home')
        commit('setFleet', true)
        commit('setAsset', 'Fleet Inventory')
        commit('setLoadingcomplete', true)
      } else {
        router.replace('home')
        commit('setFleet', false)
        commit('setAsset', response.data.assetlist[0])
        commit('setLoadingcomplete', true)
      }
    })
      .catch(err => {
        console.log('Feil ved henting av brukernavn' + '\n' + err)
        firebase
          .auth()
          .signOut()
          .then(() => {
            commit('logout')
          })
      })
  },
  setUsername ({ commit }, username) {
    commit('setUsername', username)
    commit('setFleet', true)
  },
  setassetlist ({ commit }, { list }) {
    commit('setAssetList', list)
  },
  setrouteslist ({ commit }, { list }) {
    commit('setRoutesList', list)
  },  
  setasset ({ commit }, { asset }) {
    commit('setAsset', asset)
  },
  setfleet ({ commit }, { fleet }) {
    commit('setFleet', fleet)
  },
  setToken ({ commit }, token) {
    commit('setToken', token)
  },
  setIsAdmin ({ commit }, { value }) {
    commit('setIsAdmin', value)
  },
  setPipeType ({ commit, getters }) {
    const currentPipeType = getters.getPipeType
    if (currentPipeType === 'Tubular') {
      commit('setPipeType', 'BHA')
    } else {
      commit('setPipeType', 'Tubular')
    }
  }
}

const mutations = {
  eMail: (state, pack) => {
    state.email = pack
  },
  logout: (state) => {
    state.email = '',
    state.username = '',
    state.asset = '',
    state.assetlist = [],
    state.routeslist = []
    state.fleet = false,
    state.token = null,
    state.isAdmin = false
  },
  setUsername: (state, value) => {
    state.username = value
  },
  setCustomerList: (state, value) => {
    state.customerList = value
  },
  loading: (state, value) => (state.loader = value),
  setAssetList: (state, value) => {
    state.assetlist = value
  },
  setExcludedAssets: (state, value) => {
    state.excludedAssets = value
  },
  setRoutesList: (state, value) => {
    state.routeslist = value
  },
  setLoadingcomplete: (state, value) => {
    state.loadingcomplete = value
  },
  setAsset: (state, asset) => {
    state.asset = asset
  },
  setFleet: (state, fleet) => {
    state.fleet = fleet
  },
  setToken: (state, token) => {
    state.token = token
  },
  setIsAdmin (state, value) {
    state.isAdmin = value
  },
  setPipeType: (state, pipeType) => {
    state.pipeType = pipeType
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
