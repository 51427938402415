<template>
  <v-container fluid>
    <bha_pdf_menu v-model="pdfdialog"
                  :eq="eq"
                  :subtitle="currentOrdernr"
                  :currentOrdernr="currentOrdernr"
                  :bha="bhaDialog"
                  :currentReceiveNo="currentReceiveNo"
                  :currentCOCHB="currentCOCHB"
                  :currentCOCMC="currentCOCMC"
                  :itemWithProperties="itemWithProperties"
                  :bhaInspURL="'/inspectionBHA?q='+String(currentOrdernr)"
    >
    </bha_pdf_menu>
<!--    <v-card >
      <v-card-title> Orders
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search (Order nr, PO, Item Number etc..)"
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-container fluid
        >
        <v-checkbox
          v-model="selected"
          label="Completed"
          value="completed"
        ></v-checkbox>
        <v-checkbox
          v-model="selected"
          label="Ongoing"
          value="ongoing"
        ></v-checkbox>
      </v-container>
      <v-data-table :loading="!dataLoaded" :headers="headers[$store.getters.getPipeType]" :items="filteredData" :search="search">
        <template v-slot:item.action="{ item }">
          <v-btn dark x-small text color="black" @click="getPDF(item,$store.getters.getPipeType)">
            <v-icon>description</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>-->
    <data_table
      :displayname="'Order Confirmation'"
      :options="true"
      :loader="get_data_load"
      :headers="headers[$store.getters.getPipeType]"
      :search_text="search_text"
      :items="ODP_data"
      :getPDF_func="getPDF"
      :pdf_params="[]"
      :type="$store.getters.getPipeType"
      :defaultSort="'Return_Date'"
      />
      
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import bha_pdf_menu from '@/components/BHA_order_pdf_menu.vue'
import data_table from '@/components/data_table.vue'
export default {
  name: 'neworder',
  components: { bha_pdf_menu, data_table },
  data () {
    return {
      search_text: 'Search (Order nr, PO, Item Number etc..)',
      selected: ['completed', 'ongoing'],
      search: '',
      headers: {
        Tubular: [
          { text: 'Order', align: 'start', sortable: true, value: 'Order_No' },
          { text: 'Return Date', value: 'Return_Date' },
          { text: 'Completed', value: 'Completed' },
          { text: 'PO', value: 'PO' },
          { text: 'Ref', value: 'Ref' },
          { text: 'Item No', value: 'Item_No' },
          { text: 'Equipment', value: 'Equipment' },
          { text: 'Weight', value: 'Weight' },
          { text: 'Grade', value: 'Grade' },
          { text: 'Connection', value: 'Connection_Rec' },
          { text: 'Inspection', value: 'Inspection_Spec' },
          { text: 'Scope Of Work', value: 'Scope_Of_Work' },
          { text: 'QTY', value: 'QTY_Recived' },
          { text: 'PDF', value: 'action', sortable: false }
        ],
        BHA: [
          { text: 'Order', align: 'start', sortable: true, value: 'Order_No' },
          { text: 'Return Date', value: 'Return_Date' },
          { text: 'Returned From', value: 'Returned_From' },
          { text: 'Customer rep', value: 'Customer_Rep' },
          { text: 'Ref', value: 'Ref' },
          { text: 'Completed', value: 'Completed' },
          { text: 'PO', value: 'PO' },
          { text: 'QTY', value: 'QTY' },
          { text: 'PDF', value: 'action', sortable: false }
        ]
      },
      ODP_data: { Tubular: [], BHA: [] },
      itemWithProperties: '',
      bhaDialog: false, // Show BHA menu or Tubular
      pdfdialog: false,
      // PDF variables below
      dataLoaded: false,
      insprepload: false,
      preinvpdfload: false,
      recpdfload: false,
      currentrec: '',
      currentOrdernr: '',
      currentReceiveNo: '',
      currentCOCHB: '',
      currentCOCMC: '',
      eq: '',
      orderpdfload: false
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getAsset', 'getFleet', 'getPipeType', 'get_data_load']),
    filteredData () { // Filters data using 'completed' and 'ongoing'.
      if (this.ODP_data[this.getPipeType]) {
        if (this.getFleet === true) {
          if (this.selected.length == 2) {
            return (this.ODP_data[this.getPipeType])
          } else if (this.selected == 'completed') {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed === 'Yes'
            })
          } else if (this.selected == 'ongoing') {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed !== 'Yes'
            })
          }
        } else {
          if (this.selected.length == 2) {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Asset === this.getAsset
            })
          } else if (this.selected == 'completed') {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed === 'Yes' && order.Asset === this.getAsset
            })
          } else if (this.selected == 'ongoing') {
            return (this.ODP_data[this.getPipeType]).filter(order => {
              return order.Completed === 'No' && order.Asset === this.getAsset
            })
          }
        }
      }
    }
  },
  mounted () {
    this.$initialDataLoad(this.$route.meta.backendRoutes, { username: this.$store.getters.getUsername }, 'Return_Date')
    /* axios
      .get('/getOrderconfirmTubular', { // Get Tubular data
        params: { username: this.$store.getters.getUsername }
      })
      .then(response => {
        if (response.data[1] === 401) {
        }
          this.ODP_data.Tubular = response.data[0]
      })
      .then(() => {
        axios
          .get('/getOrderconfirmBHA', { // Get BHA data
            params: { username: this.$store.getters.getUsername }
          })
          .then(response => {
            // Check if any elements;
              if (response.data[1]===401) {
                // No BHA Components, remove BHA from tabs
              }
                this.ODP_data["BHA"] = response.data[0]
                this.dataLoaded = true
          })
      }) */
  },
  methods: {
    getPDF (type, item, pdf_params) { // Show user menu with PDF choices
      const tab = this.$store.getters.getPipeType
      this.pdfdialog = true
      this.itemWithProperties = item
      if (tab === 'Tubular') {
        this.bhaDialog = false
        this.currentCOCHB = item.COC_Hardbanding
        this.currentCOCMC = item.COC_Machining
        this.currentOrdernr = item.Order_No
        this.currentReceiveNo = item.Receive_No
        this.currentrec = item.Receive_No
        this.eq = item.Equipment
      } else if (tab === 'BHA') {
        this.bhaDialog = true
        this.currentOrdernr = item.Order_No
        this.currentReceiveNo = item.Receive_No
      }
    },
    getReceivedPDF () {
      this.recpdfload = true
      axios
        .get('/getReceivedPDF', {
          params: { receivednr: this.currentrec }
        })
        .then(response => {
          this.recpdfload = false
          window.open(response.data.pdflink, '_blank')
        })
    },
    getCOC_HB () {
      window.open(this.currentCOCHB, '_blank')
    },
    getCOC_MC () {
      window.open(this.currentCOCMC, '_blank')
    },
    getOrderPDF () {
      this.orderpdfload = true
      axios
        .get('/getOrderPDF', {
          params: { orderno: this.currentOrdernr }
        })
        .then(response => {
          this.orderpdfload = false
          window.open(response.data.pdflink, '_blank')
        })
    },
    getPreinvPDF () {
      this.preinvpdfload = true
      axios
        .get('/getPreinvPDF', {
          params: { orderno: this.currentOrdernr }
        })
        .then(response => {
          this.preinvpdfload = false
          window.open(response.data.pdflink, '_blank')
        })
    },
    getExcel (item) {
      this.commonload = true
      axios
        .get('/getdpExcel', {
          params: { orderno: item.Order_No }
        })
        .then(response => {
          this.commonload = false
          window.open(response.data.excellink, '_blank')
        })
    },
    getExcelhw (item) {
      this.commonload = true
      axios
        .get('/gethwExcel', {
          params: { orderno: item.Order_No }
        })
        .then(response => {
          this.commonload = false
          window.open(response.data.excellink, '_blank')
        })
    },
    getExceldc (item) {
      this.commonload = true
      axios
        .get('/getdcExcel', {
          params: { orderno: item.Order_No }
        })
        .then(response => {
          this.commonload = false
          window.open(response.data.excellink, '_blank')
        })
    }
  }
}
</script>
<style lang="css" scoped>
.nb_button{
  width: 110px;
  text-align: center;
  color: #204060;
}
</style>
