<template>
  <v-card dark color="transparent" width="95%" class="mx-auto mt-10" elevation="0">
    <v-card-title>
        {{customer}}
            {{serialnr}}
            <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :loading="get_data_load"
      :headers="headers"
      :items="table_data"
      :search="search"
      class="custom-table"
    >
      <template v-slot:item.Result="{ item }">
        <v-chip
        :color="getColor(item.Result)"
        class="resize"

        >{{ item.Result }}</v-chip
      >
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
          dark
          x-small
          text
          @click="getPDF(item)"
        >
          <v-icon>description</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import firebase from 'firebase'
import axios from 'axios'
export default {
  name: 'keystone',
  data () {
    return {
      dataLoading: true,
      search: '',
      customer: '',
      serialnr: '',
      headers: [
        {
          text: 'Serial Nr',
          align: 'start',
          filterable: true,
          value: 'Serial_No'
        },
        { text: 'Description', value: 'Description' },
        { text: 'Connection 1', value: 'Conn1' },
        { text: 'Connection 2', value: 'Conn2' },
        { text: 'Inspection Date', value: 'Date_Formatted' },
        { text: 'PO', value: 'PO' },
        { text: 'Ref', value: 'Ref' },
        { text: 'Order No', value: 'Order_No' },
        { text: 'Spec', value: 'Spec' },
        { text: 'Result', value: 'Result' },
        { text: 'PDF', value: 'action', sortable: false }

      ],
      table_data: [
      ],
      reporttype: ''
    }
  },
  computed: {
    ...mapGetters(['get_data_load'])
  },
  mounted () {
    this.set_data_load(true)
    this.customer = this.$route.query.customer
    this.serialnr = this.$route.query.serialnr
    axios
      .get('/keystone', {
        params: { serial_no: this.serialnr }
      })
      .then(response => {
        var reportList = []
        Object.entries(response.data).forEach(function ([key, value]) {
          reportList.push.apply(reportList, value[0])
        })
        this.table_data = this.$formatData(reportList, ['Inspection_Date'])
        this.set_data_load(false)
      })
  },
  methods: {
    getPDF (item) {
      this.dataLoading = true
      this.reporttype = item.report_type
      console.log(this.reporttype);
      if (this.reporttype === 'Multi') {
        axios
          .get('/getPDF', {
            params: {
              id_name: 'Order_No',
              type: true,
              container_location: 'ODP_BHA_Multi_Detail_Report',
              id_no: item.Order_No,
              pdf_location: 'ODP_BHA_Multi_Detail_Report_Print',
              description: 'Multi_Detail',
              pdf_location2: null
            }
          })
          .then(response => {
            if (response.data.pdflink === undefined) {
              // Could not find PDF
              this.dataLoading = false
            } else {
              window.open(response.data.pdflink, '_blank')
              this.dataLoading = false
            }
          })
      } else if (this.reporttype === 'Elevator') {
        axios
          .get('/getPDF', {
            params: {
              id_name: 'Order_No',
              type: true,
              container_location: 'ODP_BHA_Elevator_Links_V2',
              id_no: item.Order_No,
              pdf_location: 'ODP_BHA_Elevator_Links_Print_V2',
              description: 'Elevator_Links',
              pdf_location2: null
            }
          })
          .then(response => {
            if (response.data.pdflink === undefined) {
              // Could not find PDF
              this.dataLoading = false
            } else {
              window.open(response.data.pdflink, '_blank')
              this.dataLoading = false
            }
          })
      } else if (this.reporttype === 'Dimentional') {
        console.log(item)
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Dimentional_Report', 'ODP_BHA_Dimentional_Report_Print', 'Dimentional', '', '', item.Serial_No]
        )
      } else if (this.reporttype === 'Miscellaneouse') {
        axios
          .get('/getPDF', {
            params: {
              id_name: 'Order_No',
              type: true,
              container_location: 'ODP_BHA_Miscellaneouse_Report',
              id_no: item.Order_No,
              pdf_location: 'ODP_BHA_Miscellaneouse_Print',
              description: 'Miscellaneous',
              pdf_location2: null
            }
          })
          .then(response => {
            if (response.data.pdflink === undefined) {
              // Could not find PDF
              this.dataLoading = false
            } else {
              window.open(response.data.pdflink, '_blank')
              this.dataLoading = false
            }
          })
      } else if (this.reporttype === 'Dimentional_OLD') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Report', 'ODP_BHA_Print', 'Dimentional', 'Order_Nr;Serial_Number', '', item.Serial_No, item.Description]
        )
      } else if (this.reporttype === 'Miscellaneouse_OLD') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_Misc_Report', 'ODP_Misc_Report_Print', 'Miscellaneous', 'Order_No;Serial_No', '', item.Serial_No, item.Description]
        )
      } else if (this.reporttype === 'Elevator_OLD') {
        this.$getPDFglobal('BHA', item,
          [{ BHA: '/getPDF' }, 'Order_No', 'ODP_BHA_Elevator_Links', 'ODP_BHA_Elevator_Links_Print', 'Elevator_Links', 'Ordre_No;Serial_Nr', '', item.Serial_No, item.Description]
        )
      }
    },
    getColor (result) {
      if (result === 'Accepted') {
        return 'green'
      } else {
        return 'red'
      }
    }
  }
}
</script>

<style scoped>
  .resize{
    width: 100px;
    text-align: center;
    justify-content: center
  }

  ::v-deep .theme--dark.v-data-table {
    background-color: #1C355E;
  }

  .v-input.v-text-field.v-select {
    max-width: 300px;
  }

  ::v-deep .v-input__slot::before {
    border-style: none !important;
  }

  .v-menu__content {
    text-align: start !important;
  }

  ::v-deep .v-data-table-header {
    background-color: rgba(255, 255, 255, 0.8);
  }

  ::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    color: #1C355E;
  }

  ::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: #1C355E !important;
  }

  ::v-deep .custom-table thead th{
    background-color: rgba(255, 255, 255, 0.9);
  }

  ::v-deep .custom-table thead th:first-child{
    border-radius: 10px 0 0 0;
  }

  ::v-deep .custom-table thead th:last-child{
    border-radius: 0 10px 0 0;
  }

  ::v-deep tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
</style>
