<template>
  <v-container fluid>
    <data_table
      :displayname="'Chiksan'"
      :loader="get_data_load"
      :options="false"
      :headers="headers"
      :items="ODP_data.Tubular"
      :search_text="search_text"
      :getPDF_func="$getPDFglobal"
      :pdf_params="pdfparams"
      :type="'Tubular'"
      :defaultSort="'Returned_Date'"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import data_table from '@/components/data_table.vue'
export default {
  name: 'chiksan',
  components: { data_table },
  data () {
    return {
      pdfparams: [{ Tubular: '/getdpPDF' }, 'Order_No'],
      search_text: 'Search (Order No, Serial Number etc..)',
      search: '',
      headers: [
        {
          text: 'Order No',
          align: 'start',
          sortable: true,
          value: 'Order_No'
        },
        { text: 'Serial Number', value: 'Serial_Number' },
        { text: 'Description', value: 'Description' },
        { text: 'Report Status', value: 'Report_Status' },
        { text: 'Inspection Date', value: 'Inspection_Date' },
        { text: 'Steel Type', value: 'Steel_Type' },
        { text: 'NDT Method', value: 'NDT_Method' },
        { text: 'MPI Method', value: 'MPI_Method' },
        { text: 'Area of Examination', value: 'Area_Of_Examination' },
        { text: 'Procedure', value: 'Procedure' },
        { text: 'PDF', value: 'action', sortable: false }
      ],
      ODP_data: { Tubular: [], BHA: [] }
    }
  },
  computed: {
    ...mapGetters(['getUsername'])
  },
  mounted () {
    this.$initialDataLoad(this.$route.meta.backendRoutes, { username: this.$store.getters.getUsername }, 'Returned_Date')
    /* this.test = true
    axios
      .get('/getdp', {
        params: { username: this.$store.getters.getUsername }
      })
      .then(response => {
        this.desserts = response.data
        this.test = false
      }) */
  },
  methods: {
    // getPDF (orderno) {
    //   this.test = true
    //   axios
    //     .get('/getdpPDF', {
    //       params: { orderno: orderno }
    //     })
    //     .then(response => {
    //       this.test = false
    //       window.open(response.data.pdflink, '_blank')
    //     })
    // }
  }
}
</script>

<style>
</style>
