<template>
  <v-container>
    <!-- INPUT -->
    <v-row style="display: flex; justify-content: center;">
      <v-col cols="4">
        <v-autocomplete v-model="selectedItem" :items="Object.keys(itemToOrders)"
          label="Select item number ..." dark>
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete v-model="selectedOrder"
          :items="itemToOrders[selectedItem] ? itemToOrders[selectedItem]['Orders'] : []"
          label="Select order number ..." dark>
        </v-autocomplete>
      </v-col>
      <v-col cols="1" align-self="center">
        <v-btn @click="fetchStats">GO</v-btn>
      </v-col>
    </v-row>

    <v-container v-if="!loading">
      <DrillPipeStatistics v-if="this.equipment === 'Drill Pipe'"
        :drillPipeData="this.componentData" @rowClicked="dpRowClicked">
      </DrillPipeStatistics>
      <DrillCollarStatistics v-if="this.equipment === 'Drill Collar'"
        :drillCollarData="this.componentData" @rowClicked="dpRowClicked">
      </DrillCollarStatistics>
      <HeavyWeightStatistics v-if="this.equipment === 'Heavy Weight'"
        :heavyWeightData="this.componentData" @rowClicked="dpRowClicked">
      </HeavyWeightStatistics>
      <OCTGStatistics
        v-if="this.equipment.toLowerCase() === 'octg' || this.equipment.toLowerCase() === 'tubing'"
        :OCTGData="this.componentData" @rowClicked="dpRowClicked">
      </OCTGStatistics>
    </v-container>
  </v-container>
</template>

<script>
import axios from 'axios';
import ItemCard from './components/ItemCard.vue'
import Histogram from './components/IPCCard.vue'
import PipeStatusPieChartCard from './components/PipeStatusPieChartCard.vue';
import TUTJ from './components/TUTJ.vue';
import { CONSTANTS } from './constants.js'
import DrillPipeStatistics from './components/DrillPipeStatisticsView.vue';
import DrillCollarStatistics from './components/DrillCollarStatisticsView.vue';
import HeavyWeightStatistics from './components/HeavyWeightStatisticsView.vue';
import OCTGStatistics from './components/OCTGStatisticsView.vue';
export default {

  components: {
    ItemCard,
    Histogram,
    PipeStatusPieChartCard,
    TUTJ,
    DrillPipeStatistics,
    DrillCollarStatistics,
    HeavyWeightStatistics,
    OCTGStatistics,
  },
  watch: {
    selectedItem: function (newSelectedItem, oldSelectedItem) {
      this.equipment = this.itemToOrders[newSelectedItem]["Equipment"];
      this.loading = true;
      this.selectedOrder = "";
    },
    selectedOrder: function (newSelectedOrder, oldSelectedOrder) {
      this.loading = true;
    }
  },
  computed: {
    itemNos() {
      return Object.keys(this.itemNos)
    },
    equipmentComputed: () => {
      return this.equipment
    },
    machining_card_title: () => {
      return CONSTANTS.MACHINING_CARD_TITLE + " " + "x Drill Pipes"
    },
    hardbanding_card_title: () => {
      return CONSTANTS.HARDBANDING_CARD_TITLE + " " + "x Drill Pipes"
    }
  },
  data() {
    return {
      equipment: '',
      loading: true,
      hasIPC: false,
      MACHINING_CARD_TITLE: CONSTANTS.MACHINING_CARD_TITLE,
      HARDBANDING_CARD_TITLE: CONSTANTS.HARDBANDING_CARD_TITLE,
      MACHINING_BOX: 'Connection Status - Box',
      MACHINING_PIN: 'Connection Status - Pin',
      TU_CARD_TITLE: CONSTANTS.TU_CARD_TITLE,
      TJ_CARD_TITLE: CONSTANTS.TJ_CARD_TITLE,
      itemToOrders: {},
      selectedItem: '',
      selectedOrder: '',
      orderDataTableHeaders: [
        {
          text: 'Order nr',
          align: 'start',
          sortable: true,
          value: 'Order_No'
        },
        { text: "Asset", sortable: true, value: "Asset" },
        { text: "Quantity", sortable: true, value: "Quantity" },
        { text: "Connection pin recut sum", sortable: true, value: "Machining_Pin_Sum_Recut" },
        { text: "Connection box recut sum", sortable: true, value: "Machining_Box_Sum_Recut" },
      ],
      listOfOrderData: [],
      yAxisMapper: {
        "SRF": "Shop Reface",
        "PS": "Pitting Seal",
        "PT": "Pitting Thread",
        "GS": "Galling Seal",
        "GT": "Galling Shoulder",
        "MTD": "Mechanical Thread Damage",
        "MSD": "Mechanical Seal Damage",
        "TL": "Thread Length Incorrect",
        "OT": "Over Torque",
        "REJ": "Reject", // Hardbanding and Machining
        "STA": "Stabbing Damage",
        "SUM": "Sum Recut",
        "OK": "OK",
        "D": "Damage", // Hardbanding
        "E": "Eccentric", // Hardbanding,
        "F": "Flush", // Hardbanding,
        "S": "Spalling", // Hardbanding,
        "DS18D": "Damage Softlayer and 18 Degree", // Hardbanding
        "SUM HB": "Sum Hardbanding", // Hardbanding
      },
      BGCMappingIPC: {
        "1": "#4CAF50",
        "2": "#81C784",
        "3": "#FFEB3B",
        "4": "#FFC107",
        "5": "#F44336",
        "A": "#4CAF50",
        "B": "#81C784",
        "C": "#FFEB3B",
        "D": "#FFC107",
        "E": "#F44336",
      },
      itemData: {
        Customer: 'Odfjell Technology',
        Order_No: 11930,
        Item_No: 'OWS578DP.05',
        Spec: 'NS2',
        Range: 2,
      },
      Pipe_Status: {
        Qty_Received: 259,
        Rig_Ready: 75,
        Rig_Ready_After_Repair: 183,
        Machine_Shop_Repair: 181,
        Hardbanding_Repair: 21,
        Scrap_Or_LS: 1,
        HB_And_Machining: 19
      },
      Machining_Data: {
        Pin: {
          SRF_Shop_Reface: 57,
          PS_Pitting_Seal: 0,
          GS_Galling_Seal: 0,
          GT_Galling_Shoulder: 0,
          MTD_Mechanical_Thread_Damage: 0,
          MSD_Mechanical_Seal_Damage: 2,
          TL_Thread_Length_Incorrect: 0,
          OT_Over_Torque: 0,
          Rej_Reject: 0,
          STA_Stabbing_Damage: 2,
          OK: 0,
          Sum_Recut: 4
        },
        Box: {
          SRF_Shop_Reface: 126,
          PS_Pitting_Seal: 0,
          GS_Galling_Seal: 0,
          GT_Galling_Shoulder: 0,
          MTD_Mechanical_Thread_Damage: 0,
          MSD_Mechanical_Seal_Damage: 1,
          TL_Thread_Length_Incorrect: 0,
          OT_Over_Torque: 0,
          Rej_Reject: 16,
          STA_Stabbing_Damage: 0,
          OK: 0,
          Sum_Recut: 17
        }
      },
      Hardbanding: {
        Pin: {
          D_Damage: 0,
          E_Eccentric: 0,
          F_Flush: 0,
          S_Spalling: 0,
          Rej_Reject: 0,
          Damage_Softlayer_And_18Degree: 0,
          Sum_HB: 0,
          OK: 0,
        },
        Box: {
          D_Damage: 21,
          E_Eccentric: 0,
          F_Flush: 0,
          S_Spalling: 0,
          Rej_Reject: 0,
          Damage_Softlayer_And_18Degree: 0,
          Sum_HB: 21,
          OK: 0,
        }
      },
      IPC: {
        A_1: 259,
        B_2: 0,
        C_3: 0,
        D_4: 0,
        E_5: 0
      },
      TU_TJ_mapper: {
        "P": "Premium",
        "L": "Limited Service",
        "S": "Scrap"
      },
      TU_Tube: {
        Premium: 258,
        Limited_Service: 0,
        Scrap: 1
      },
      TJ_Tooljoint: {
        Premium: 259,
        Limited_Service: 0,
        Scrap: 0
      },
      tongSpace: {},
      od: {},
      wall: {},
      componentData: {},
      multiHorisontalChartColorMapping: {
        Total: '#2b1d0e',
        Pin: '#70675d',
        Box: '#aab1b7'

      }
    }
  },
  mounted() {
    axios
      .get('/itemsAndOrders', {
        params: {
          username: this.$store.getters.getUsername
        }
      })
      .then(response => {
        const tempDict = {}
        Object.keys(response.data).forEach((key) => {
          if (["Drill Pipe", "Drill Collar", "Tubing", "OCTG", "Heavy Weight"].includes(response.data[key].Equipment)) {
            tempDict[key] = response.data[key]
          }
        });
        this.itemToOrders = tempDict;
      });
  },
  methods: {
    dpRowClicked(row) {
      this.selectedOrder = row.Order_No;
      this.fetchStats();
    },
    fetchStats() {
      this.equipment = this.itemToOrders[this.selectedItem]["Equipment"];
      axios
        .get('/statistics/item', {
          params: {
            itemNumber: this.selectedItem,
            orderNumber: this.selectedOrder,
            equipment: this.itemToOrders[this.selectedItem]["Equipment"]
          }
        })
        .then(response => {
          console.log(response.data)
          this.componentData.IPC = response.data.IPC;
          this.componentData.Machining_Data = Object.entries(response.data.Machining_Data).map(([key, val]) => {
            return { data: val, name: key, color: this.multiHorisontalChartColorMapping[key] }
          })
          this.componentData.Hardbanding = Object.entries(response.data.Hardbanding ? response.data.Hardbanding : []).map(([key, val]) => {
            return { data: val, name: key, color: this.multiHorisontalChartColorMapping[key] }
          })
          this.componentData.pipeStatus = response.data.Pipe_Status;
          this.componentData.TJ_Tooljoint = response.data.TJ_Tooljoint;
          this.componentData.TU_Tube = response.data.TU_Tube;
          this.componentData.itemData = response.data.ItemData;
          this.componentData.tongspace = response.data.Tongspace;
          this.componentData.od = response.data.OD;
          this.componentData.wall = response.data.Wall
          this.componentData.listOfOrderData = response.data.Orders
          this.componentData.minimum_values = response.data.minimum_values
          console.log("Component data", this.componentData);
          console.log(response.data);
          this.loading = false
        });
    },
    // formatTSOD: function (seriesName, dataset) {
    //   let data = {};
    //   data["series"] = [
    //     {
    //       name: seriesName,
    //       data: dataset.y,
    //     }
    //   ];
    //   data["options"] = {
    //     chart: {
    //       type: 'bar',
    //       foreColor: '#fff',
    //       toolbar: {
    //         show: false,
    //       },
    //     },
    //     tooltip: {
    //       theme: 'dark',
    //       shared: true,
    //       intersect: false,
    //     },
    //     xaxis: {
    //       categories: dataset.x,
    //     },
    //   }
    //   return data
    // },
    // formatDataIPC: function () {
    //   let IPCData = {};
    //   IPCData['series'] = [
    //     {
    //       name: "IPC Status",
    //       data: Object.values(this.IPC),
    //     }
    //   ];
    //   IPCData['options'] = {
    //     chart: {
    //       type: 'bar',
    //       foreColor: '#fff',
    //       toolbar: {
    //         show: false,
    //       },
    //     },
    //     tooltip: {
    //       theme: 'dark',
    //       shared: true,
    //       intersect: false,
    //     },
    //     xaxis: {
    //       categories: Object.keys(this.IPC),
    //     },
    //     colors: Object.keys(this.IPC).map((key) => {
    //       return this.BGCMappingIPC[key];
    //     }),
    //   };
    //   return IPCData;
    // },
    formatDataPie: function (data, chartTitle) {
      if (data === undefined) {
        return {};
      }
      let pieData = {};
      pieData['series'] = Object.values(data);
      pieData['options'] = {
        chart: {
          width: '100%',
          type: 'pie',
          minHeight: '200px'
        },
        labels: Object.keys(data),
        title: {
          text: chartTitle,
          style: {
            color: '#fff',
            fontSize: '20px',
            fontFamily: 'Arial',
            fontWeight: 'lighter'
          }
        },
        theme: {
          monochrome: {
            enabled: true,
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + '%']
          }
        },
        legend: {
          show: true,
          labels: {
            colors: ['#fff'],
          },
          fontSize: '14px'
        }
      };
      return pieData;
    },
    formatBarDataH: function (data) {
      let barData = {};
      barData['series'] = [
        { data: Object.values(data.Box), name: 'Box', color: '#aab1b7' },
      ];
      barData['options'] = {
        chart: {
          type: 'bar',
          foreColor: '#fff',
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        tooltip: {
          theme: 'dark',
          followCursor: true,
          shared: true,
          intersect: false,
          y: {
            title: {
              formatter: (value, { seriesIndex, dataPointIndex, w }) => {
                return `${this.yAxisMapper[w.globals.labels[dataPointIndex]]} ${value}`
              }
            }
          },
        },
        xaxis: {
          categories: Object.keys(data.Box),
          labels: {
            show: true,
            align: 'center',
            style: {
              colors: ['#fff'],
              fontSize: '12px',
            },
          }
        },
        yaxis: {
          labels: {
            show: true,
            align: 'left',
            style: {
              colors: ['#fff'],
              fontSize: '16px',
            },
          },
          tooltip: {
            enabled: false,
          }
        },
        legend: {
          show: true,
        }
      }
      return barData;
    },
    formatBarData: function (data) {
      let barData = {};
      barData['series'] = [
        { data: Object.values(data.Total), name: 'Total', color: '#2b1d0e' },
        { data: Object.values(data.Pin), name: 'Pin', color: '#70675d' },
        { data: Object.values(data.Box), name: 'Box', color: '#aab1b7' },
      ];
      barData['options'] = {
        chart: {
          type: 'bar',
          foreColor: '#fff',
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          }
        },
        tooltip: {
          theme: 'dark',
          followCursor: true,
          shared: true,
          intersect: false,
          y: {
            title: {
              formatter: (value, { seriesIndex, dataPointIndex, w }) => {
                return `${this.yAxisMapper[w.globals.labels[dataPointIndex]]} ${value}`
              }
            }
          },
        },
        xaxis: {
          categories: Object.keys(data.Pin),
          labels: {
            show: true,
            align: 'center',
            style: {
              colors: ['#fff'],
              fontSize: '12px',
            },
          }
        },
        yaxis: {
          labels: {
            show: true,
            align: 'left',
            style: {
              colors: ['#fff'],
              fontSize: '16px',
            },
          },
          tooltip: {
            enabled: false,
          }
        },
        legend: {
          show: true,
        }
      }
      return barData;
    }
  }
}
</script>
<style scoped>
::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  color: #1C355E;
}

::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #1C355E !important;
}

::v-deep .custom-table thead th {
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child {
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child {
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
</style>