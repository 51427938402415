<script>
import VueApexCharts from 'vue-apexcharts';
import {CONSTANTS} from '../constants.js';
export default {
  name: 'ItemCard',
  props: {
    pipeStatus: {
      type: Object,
    },
    cardTitle: '',
  },
  components: {
    VueApexCharts
  },

  data () {
    return {
      CARD_TITLE: CONSTANTS.PIPE_STATUS_CARD_TITLE,
    }
  }

}
</script>

<template>
  <v-card class="pa-2 cardWidth" min-height="338px" dark color="#1C355E"
    elevation="0">
    <v-card-title>
      {{ cardTitle }}
    </v-card-title>
    <VueApexCharts type="pie" :options="pipeStatus.options" :series="pipeStatus.series"></VueApexCharts>
    </v-card>
</template>