var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"width":"400"},on:{"input":_vm.test}},[_c('v-card',{attrs:{"dark":"","color":"#1C355E"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.title))]),_c('v-list-item-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.subtitle))])],1)],1),_c('v-progress-linear',{attrs:{"active":_vm.get_dialog_load,"indeterminate":_vm.get_dialog_load,"absolute":"","color":"blue"}}),_c('v-divider'),_c('v-list',{attrs:{"rounded":"","light":"","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.$getPDFglobal(
              undefined,_vm.itemWithProperties,[
                { Tubular: '/getReceivedPDF', BHA: '/getPDF' },
                'Receive_No',
                'ODP_BHA_Receive_Report',
                'ODP_BHA_Receive_Report_Print',
                'Receive' ]
            )}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-button text-left"},[_vm._v("Receive")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.$getPDFglobal(
              undefined,_vm.itemWithProperties,[
                { Tubular: '/getOrderPDF', BHA: '/getPDF' },
                'Order_No',
                'ODP_BHA_Order_Confirmation_V1',
                'ODP_BHA_Order_Confirmation_Print',
                'Order_Confirmation' ]
            )}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-button text-left"},[_vm._v("Order Confirmation")])],1)],1),(!_vm.blacklist.includes(_vm.getEmail))?_c('v-list-item',{on:{"click":function($event){return _vm.$getPDFglobal(
              undefined,_vm.itemWithProperties,[
                { Tubular: '/getPreinvPDF', BHA: '/getPDF' },
                'Order_No',
                'ODP_BHA_Pre_Invoice_V2',
                'ODP_BHA_Pre_Invoice_V2_Print',
                'Pre_Invoice',
                'ODP_BHA_Pre_Invoice_V2_Repair_Print',
                'ODP_BHA_Pre_Invoice_V2_Machining_Print' ]
            )}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-button text-left"},[_vm._v("Pre Invoice")])],1)],1):_vm._e(),(_vm.bha)?_c('v-list-item',{attrs:{"to":_vm.bhaInspURL}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-button text-left"},[_vm._v("Inspection Reports")])],1)],1):_vm._e(),(!_vm.bha)?_c('v-list-item',{on:{"click":_vm.getCOC_MC}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-button text-left"},[_vm._v("COC Machining")])],1)],1):_vm._e(),(!_vm.bha)?_c('v-list-item',{on:{"click":_vm.getCOC_HB}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-button text-left"},[_vm._v("COC Hardbanding")])],1)],1):_vm._e(),(!_vm.bha)?_c('v-list-item',{on:{"click":_vm.getInspectionTypePDF}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-button text-left"},[_vm._v("Inspection Report PDF")])],1)],1):_vm._e(),(!_vm.bha)?_c('v-list-item',{on:{"click":_vm.getInspectionTypeEXCEL}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-button text-left"},[_vm._v("Inspection Report EXCEL")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }