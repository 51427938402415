import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import firebase from 'firebase'
import Navbar from '../views/Navbar.vue'
import Racklocation from '../views/Racklocation.vue'
import Test from '../views/Test.vue'
import Racks from '../views/Racks.vue'
import Inventory from '../views/Inventory.vue'
import store from '../store'
import Orderconfirm from '../views/Orderconfirm.vue'
import Received from '../views/Received.vue'
import Preinvoice from '../views/PreInvoice.vue'
import Delivery from '../views/Delivery.vue'
import DrillPipe from '../views/dp.vue'
import HeavyWeight from '../views/hw.vue'
import DrillCollar from '../views/dc.vue'
import OCTG from '../views/octg.vue'
import Asset from '../views/asset.vue'
import OngoingOrders from '../views/Ongoingorders.vue'
import CompletedOrders from '../views/completedorders.vue'
import NewOrder from '../views/neworder.vue'
import InspectionBHA from '../views/inspectionBHA.vue'
import Keystone from '../views/keystone.vue'
import admin from '@/views/Admin/admin'
import users from '@/views/Admin/users'
import KCAD from '@/views/KCAD.vue'
import BHAInventory from '@/views/BHAInventory.vue'
import QHSE from '@/views/qhse.vue'
import Statistics from '@/views/Statistics.vue'
import Certificates from '@/views/certificates.vue'
import customers from '@/views/Admin/customers'
import iteminspectionhistory from '@/views/ItemInspectionHistory'
import inspectionstats from '@/views/inspection_stats.vue'
import ItemOrderStatistics from '@/views/Statistics/ItemOrderStatistics.vue'
import chiksan from '@/views/chiksan.vue'
import Chiksan from '../views/chiksan.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      displayname: 'Home',
      hasBHAswitch: false
    }
  },
  {
    path: '/racks',
    name: 'Racks',
    component: Racks,
    hasBHAswitch: false
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: {
      requiresAuth: true,
      hasBHAswitch: false
    }
  },
  {
    path: '/inspection-view/',
    name: 'insptest',
    component: iteminspectionhistory,
    meta: {
      requiresAuth: true,
      hasBHAswitch: false,
      displayname: "Inspection"
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/navbar',
    name: 'navbar',
    component: Navbar,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: Inventory,
    meta: {
      requiresAuth: true,
      displayname: 'Inventory',
      hasBHAswitch: false
    }
  },
  {
    path: '/received',
    name: 'received',
    component: Received,
    meta: {
      requiresAuth: true,
      displayname: 'Received',
      hasBHAswitch: false,
      backendRoutes: {
        BHA: '',
        Tubular: '/getReceived'
      }
    }
  },
  {
    path: '/preinvoice',
    name: 'preinvoice',
    component: Preinvoice,
    meta: {
      requiresAuth: true,
      displayname: 'Pre Invoice',
      hasBHAswitch: true,
      load: false,
      backendRoutes: {
        BHA: '/getPreInvoiceBHA',
        Tubular: '/getPreinv'
      }
    }
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: Delivery,
    meta: {
      requiresAuth: true,
      backend: '/getDelivery',
      displayname: 'Shipping',
      hasBHAswitch: true,
      backendRoutes: {
        BHA: '/getDeliveryBHA',
        Tubular: '/getDelivery'
      }
    }
  },
  {
    path: '/dp',
    name: 'dp',
    component: DrillPipe,
    meta: {
      requiresAuth: true,
      displayname: 'Drill Pipe',
      hasBHAswitch: false,
      backendRoutes: {
        BHA: '',
        Tubular: '/getdp'
      }
    }
  },
  {
    path: '/chiksan',
    name: 'chiksan',
    component: Chiksan,
    meta: {
      requiresAuth: true,
      displayname: 'Chiksan',
      hasBHAswitch: false,
      backendRoutes: {
        BHA: '',
        Tubular: '/getChiksan'
      }
    }
  },
  {
    path: '/dc',
    name: 'dcollar',
    component: DrillCollar,
    meta: {
      requiresAuth: true,
      displayname: 'Drill Collar',
      hasBHAswitch: false,
      backendRoutes: {
        BHA: '',
        Tubular: '/getdc'
      }
    }
  },
  {
    path: '/hw',
    name: 'heavy',
    component: HeavyWeight,
    meta: {
      requiresAuth: true,
      displayname: 'Heavy Weight',
      hasBHAswitch: false,
      backendRoutes: {
        BHA: '',
        Tubular: '/gethw'
      }
    }
  },
  {
    path: '/octg',
    name: 'octg',
    component: OCTG,
    meta: {
      requiresAuth: true,
      displayname: 'OCTG',
      hasBHAswitch: false,
      backendRoutes: {
        BHA: '',
        Tubular: '/getoctg'
      }
    }
  },
  {
    path: '/keystone',
    name: 'keystone',
    component: Keystone,
    meta: {
      requiresAuth: false,
      hasBHAswitch: false
    }
  },
  /* {
    path: '/asset',
    name: 'asset',
    component: Asset,
    meta: {
      requiresAuth: true,
      hasBHAswitch: false
    }
  }, */
  {
    path: '/ongoingorders',
    name: 'ongoingorders',
    component: OngoingOrders,
    meta: {
      requiresAuth: true,
      displayname: 'Ongoing Orders',
      hasBHAswitch: true
    }
  },
  {
    path: '/completedorders',
    name: 'completedorders',
    component: CompletedOrders,
    meta: {
      requiresAuth: true,
      displayname: 'Completed Orders',
      hasBHAswitch: true
    }
  },
  {
    path: '/racklocation',
    name: 'racklocation',
    component: Racklocation,
    meta: {
      requiresAuth: true,
      displayname: 'Rack Location',
      hasBHAswitch: false
    }
  },
  {
    path: '/bhainventory',
    name: 'bhainventory',
    component: BHAInventory,
    meta: {
      requiresAuth: true,
      displayname: 'BHA Inventory',
      hasBHAswitch: false
    }
  },
  {
    path: '/orderconfirm',
    name: 'neworder',
    component: NewOrder,
    meta: {
      requiresAuth: true,
      displayname: 'Order Confirmation',
      hasBHAswitch: true,
      backendRoutes: {
        BHA: '/getOrderconfirmBHA',
        Tubular: '/getOrderconfirmTubular'
      }
    }
  },
  {
    path: '/inspectionBHA',
    name: 'inspectionBHA',
    component: InspectionBHA,
    meta: {
      requiresAuth: true,
      displayname: 'Inspection',
      hasBHAswitch: false
    }
  },
  {
    path: '/KCADeutag',
    name: 'KCAD',
    component: KCAD,
    meta: {
      requiresAuth: false,
      hasBHAswitch: false
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Statistics,
    meta: {
      requiresAuth: true,
      displayname: 'Statistics',
      hasBHAswitch: false
    }
  },
  {
    path: '/NCR',
    name: 'NCR',
    component: QHSE,
    meta: {
      requiresAuth: true,
      displayname: 'Non Conformance',
      hasBHAswitch: false
    }
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: Certificates,
    meta: {
      requiresAuth: true,
      displayname: 'Certificates',
      hasBHAswitch: false
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: admin,
    meta: {
      requiresAuth: true,
      displayname: 'Home',
      requiresAdmin: true,
      hasBHAswitch: false
    }
  },
  {
    path: '/users',
    name: 'users',
    component: users,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      hasBHAswitch: false
    }
  },
  {
    path: '/customers',
    name: 'customers',
    component: customers,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      hasBHAswitch: false
    }
  },
  {
    path: '/inspection-stats',
    name: 'inspection-stats',
    component: inspectionstats,
    meta: {
      requiresAuth: true,
      hasBHAswitch: false
    }
  },
  {
    path: '/item-statistics',
    name: 'item-statistics',
    component: ItemOrderStatistics,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      hasBHAswitch: false,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser) {
    currentUser.getIdToken(true).then(idToken => {
      store.dispatch('setToken', idToken)
    })
  }
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  const isAdmin = store.getters.getIsAdmin
  if (requiresAuth) {
    const access = store.getters.getRouteslist
    if (access.length > 0) {
      if (to.path.toLocaleLowerCase() === '/home') {
        next(access[0])
        return true
      }
      if (!access.includes(to.name)) {
        return false
      }
    }
  }

  if (requiresAuth && !currentUser) next('login')
  else if (!isAdmin && requiresAdmin && requiresAuth) next('Home')
  else if (!requiresAuth && to.name === 'keystone') next()
  else if (!requiresAuth && currentUser) next('Home')
  else next()
})

export default router
