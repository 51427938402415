<template>
<v-card dark color="transparent" width="95%" class="mx-auto mt-10" elevation="0">
  <v-dialog v-model="newCustomerDialog" max-width="600px" persistent>
    <v-card dark>
      <v-toolbar :color="toolbarColor">
        <v-spacer/>
        <v-toolbar-title>
          {{ userAction }}
        </v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="newCustomerForm" ref="newCustomerForm">
          <v-container>
            <v-row>
              <v-col cols="8">
                <v-text-field v-model="new_customer_name" label="Customer" required :rules="fieldRules.required"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="new_customer_id" label="Customer ID" required v-if="userAction === 'Edit Customer'" disabled></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="new_org_no" label="Organization No." required :rules="fieldRules.required"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="new_rig" label="Rig"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="asset"
                  :items="new_assets"
                  multiple
                  label="Assets"
                  item-text="ODP_Customer_Asset::Asset_name"
                  return-object
                  required>
                  <template v-slot:prepend-item>
                    <v-text-field label="Asset Name" class="px-3" v-model="new_asset_name">
                      <template v-slot:append>
                        <v-btn icon small @click="create_new_asset" class="mx-1" :disabled="asset.length !== 0 || new_asset_name.length < 1">
                          <v-icon>mdi-account-plus</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:append-outer>
                        <v-btn icon small @click="removeAssets" class="mx-1" :disabled="asset.length < 1">
                          <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeNewCustomerDialog" text color="error" plain :disabled="confirmBtnLoader">Cancel</v-btn>
        <v-btn v-if="userAction==='Create Customer'" @click="createNewUser" text color="primary" plain :disabled="!newCustomerForm" :loading="confirmBtnLoader">Confirm</v-btn>
        <v-btn v-else-if="userAction==='Edit Customer'" @click="confirmAction('editCustomer')" text color="primary" plain :disabled="!newCustomerForm" :loading="confirmBtnLoader">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="deleteDialog" max-width="300px" persistent>
    <v-card dark>
      <v-card-text class="pt-5 text-left">
        {{ deleteText }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="clear_assets" text color="error" plain :disabled="confirmBtnLoader">Cancel</v-btn>
        <v-btn text color="primary" plain @click="confirmAction('deleteCustomer')" :loading="confirmBtnLoader">Confirm</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-card-title>
    Customer Overview

        <v-btn icon class="ml-5" @click="currentAction('')">
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
    <v-spacer></v-spacer>
    <v-text-field
      v-model="search"
      prepend-icon="mdi-magnify"
      label="Search (Name, Email etc..)"
      single-line
      hide-details
    ></v-text-field>
  </v-card-title>
  <v-data-table
    class="custom-table"
    :loading="get_data_load"
    :headers="headers"
    :items="allCustomers"
    :sort-desc="sortDesc"
    :search="search">
    <template v-slot:item.action="{ item }">
      <v-btn icon small @click="currentAction(item)">
        <v-icon>mdi-account-edit</v-icon>
      </v-btn>
      <v-btn icon small @click="deleteItem('customer', item)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</v-card>
</template>

<script>
import axios from 'axios'
import firebase from 'firebase'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'customers',
  data () {
    return {
      sortDesc: true,
      newCustomerForm: false,
      deleteDialog: false,
      confirmBtnLoader: false,
      toolbarColor: '#1C355E',
      selectedCustomer: null,
      new_asset_name: '',
      deleteText: '',
      userAction: '',
      fieldRules: {
        required: [(v) => !!v || 'This field is required'],
        emailfield: [(v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(v).toLowerCase()) || 'E-mail must be valid']
      },
      search: '',
      allUsers: [],
      allCustomers: [],
      loader: false,
      asset: [],
      new_customer_name: '',
      new_customer_id: '',
      new_org_no: '',
      new_rig: '',
      new_assets: [],
      new_email: '',
      recordid: '',
      new_phone: '',
      newCustomerDialog: false,
      headers: [
        { text: 'Customer', value: 'customer' },
        { text: 'Customer ID', value: 'customer_id', sortable: false },
        { text: 'Organization No.', value: 'org_no' },
        { text: 'Rig', value: 'rig' },
        { text: '', value: 'action', sortable: false }
      ]
    }
  },
  mounted () {
    this.set_data_load(true)
    axios.get('/getAllCustomers')
      .then((res) => {
        this.allCustomers = res.data.customerList
        this.set_data_load(false)
      })
  },
  computed: {
    ...mapGetters(['get_data_load'])
  },
  methods: {
    ...mapActions(['set_data_load']),
    removeAssets() {
      if (this.asset.length > 0) {
        var res = this.new_assets.filter(x => !this.asset.includes(x))
        this.new_assets = res
      }
      this.clear_assets()
    },
    confirmAction(type) {
      if (type === 'editCustomer') {
        axios.post('/editCustomer', {
          customer: this.selectedCustomer,
          assets: this.new_assets
        })
        console.log(this.selectedCustomer);
        console.log(this.new_assets);
      } else if (type === 'deleteCustomer') {
        // Confirm changes for selected customer
        console.log(this.selectedCustomer);
      }
      this.closeNewCustomerDialog()
    },
    create_new_asset() {
      if (!this.new_assets.includes(this.new_asset_name)) {
        this.new_assets = this.new_assets.concat([this.new_asset_name])
      }
      this.clear_assets()
    },
    clear_assets () {
      this.deleteDialog = false
      this.new_asset_name = ''
      this.asset = []
    },
    deleteItem (item, customer) {
      if (item === "asset") {
        this.deleteText = 'Are you sure you want to confirm changes?'
      } else if (item === "customer") {
        this.selectedCustomer = customer
        this.deleteText = 'Are you sure you want to delete ' + customer.customer + '?'
      }
      this.deleteDialog = true
    },
    currentAction (item) {
      if (item) {
        this.userAction = 'Edit Customer'
        this.newCustomerDialog = true
        this.selectedCustomer = item
        this.new_customer_name = item.customer
        this.new_customer_id = item.customer_id
        this.new_org_no = item.org_no
        this.new_rig = item.rig
        this.new_assets = item.assets
        this.recordid = item.recordid
      } else {
        this.userAction = 'Create Customer'
        this.newCustomerDialog = true
        this.selectedCustomer = null
        this.new_customer_name = ''
        this.new_customer_id = ''
        this.new_org_no = ''
        this.new_rig = ''
        this.new_assets = []
        this.recordid = ''
      }
    },
    closeNewCustomerDialog () {
      this.clear_assets()
      this.userAction = ''
      this.new_customer_name = ''
      this.new_customer_id = ''
      this.new_org_no = ''
      this.new_rig = ''
      this.new_assets = []
      this.recordid = ''
      this.toolbarColor = '#1C355E'
      this.$refs.newCustomerForm.resetValidation()
      this.newCustomerDialog = false
    },
    deleteCurrentCustomer () {
      this.confirmBtnLoader = true
      axios.post('/deleteCurrentUser', this.selectedUser)
        .then(() => {
          this.confirmBtnLoader = false
          const idx = this.allUsers.map(e => e.userid).indexOf(this.selectedUser.userid)
          this.allUsers.splice(idx, 1)
          this.deleteUserDialog = false
        })
        .catch((err) => {
          this.confirmBtnLoader = false
          console.log(err)
        })
    },
    editCurrentUser () {
      this.confirmBtnLoader = true
      axios.post('/editCurrentUser', {
        old_email: this.selectedUser.email,
        new_email: this.new_email,
        phone: this.new_phone,
        recordid: this.recordid
      })
        .then(() => {
          this.toolbarColor = 'success'
          this.confirmBtnLoader = false
          const idx = this.allUsers.map(e => e.userid).indexOf(this.userid)
          this.allUsers[idx].phone = this.new_phone
          this.allUsers[idx].email = this.new_email
          this.closeNewCustomerDialog()
        })
        .catch((err) => {
          this.toolbarColor = 'error'
          this.confirmBtnLoader = false
          console.log(err)
        })
    },
    createNewUser () {
      this.confirmBtnLoader = true
      axios.post('/createNewUser', {
        email: this.new_email,
        phone: this.new_phone
      })
        .then((res) => {
          this.toolbarColor = 'success'
          this.confirmBtnLoader = false
          this.allUsers.push({
            email: this.new_email,
            phone: this.new_phone,
            recordid: res.data.recordid
          })
          this.closeNewCustomerDialog()
        })
        .catch((err) => {
          this.toolbarColor = 'error'
          this.confirmBtnLoader = false
          console.log(err)
        })
    }
  }
}
</script>

<style scoped>
::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

.v-input.v-text-field.v-select {
  max-width: 300px;
}

.v-menu__content {
  text-align: start !important;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: #1C355E;
}

::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #1C355E !important;
}

::v-deep .custom-table thead th{
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child{
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child{
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

</style>
