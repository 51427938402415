<script>
import { CONSTANTS } from '../constants.js';
import ItemCard from '../components/ItemCard.vue'
import Histogram from '../components/IPCCard.vue'
import PipeStatusPieChartCard from '../components/PipeStatusPieChartCard.vue';
import HorizontalBarCard from '../components/HorizontalBarCard.vue'
import { barData, horisontalMultiBarData, formatDataPie } from '../dataformatter';
import OrderBreakdown from './OrderBreakdown.vue';

export default {
  name: 'HeavyWeightStatistics',
  props: {
    heavyWeightData: {
      type: Object,
    },
  },
  components: {
    ItemCard, Histogram, PipeStatusPieChartCard, HorizontalBarCard, OrderBreakdown
  },
  data() {
    return {
      machining_card_title: CONSTANTS.MACHINING_CARD_TITLE,
      hardbanding_card_title: CONSTANTS.HARDBANDING_CARD_TITLE,
      orderDataTableHeaders: [
        {
          text: 'Order nr',
          align: 'start',
          sortable: true,
          value: 'Order_No'
        },
        { text: "Asset", sortable: true, value: "Asset" },
        { text: "Quantity", sortable: true, value: "Quantity" },
        { text: "Connection pin recut sum", sortable: true, value: "Machining_Pin_Sum_Recut" },
        { text: "Pin SRF", value: "Machining_Pin_SRF" },
        { text: "Connection box recut sum", sortable: true, value: "Machining_Box_Sum_Recut" },
        { text: "Box SRF", value: "Machining_Box_SRF" },
      ],
    }
  },
  methods: {
    barData,
    horisontalMultiBarData,
    formatDataPie,
    handleRowClick(row) {
      this.$emit("rowClicked", row)
    }
  }
}
</script>


<template>
  <v-container>
    <!-- 1st row -->
    <v-row class="ma-2">
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E" elevation="0">
          <v-card-title>General</v-card-title>
          <ItemCard :itemObject="this.heavyWeightData.itemData" />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>IPC Status</v-card-title>
          <Histogram
            :IPCData="barData('IPC Status', Object.keys(this.heavyWeightData.IPC), Object.values(this.heavyWeightData.IPC))"
            :title="'IPC Status'" />
        </v-card>
      </v-col>
    </v-row>

    <!-- 2nd row -->
    <v-row class="ma-2">
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>{{ 'TS Pin' + ' (Min criteria: ' +
            this.heavyWeightData.minimum_values.TS_Pin + ')' }}</v-card-title>
          <Histogram
            :IPCData="barData('Tongspace Pin', this.heavyWeightData.tongspace.Pin.x, this.heavyWeightData.tongspace.Pin.y)"
            :title="'Tongspace Pin'" />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>
            {{ 'TS Box' + ' (Min criteria: ' +
              this.heavyWeightData.minimum_values.TS_Box + ')' }}
          </v-card-title>
          <Histogram
            :IPCData="barData('Tongspace Box', this.heavyWeightData.tongspace.Box.x, this.heavyWeightData.tongspace.Box.y)"
            :title="'Tongspace Box'" />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E">
          <v-card-title>{{ 'Box OD' + ' (Min criteria: ' +
            this.heavyWeightData.minimum_values.OD_Box + ')'
          }}</v-card-title>
          <Histogram
            :IPCData="barData('Box OD', this.heavyWeightData.od.Box.x, this.heavyWeightData.od.Box.y)" />
        </v-card>
      </v-col>
    </v-row>

    <!-- 4th row -->
    <v-row class="ma-2">
      <v-col cols="12">
        <v-card class="pa-2 cardWidth" min-height="800px" dark color="#1C355E">
          <v-card-title>{{ this.hardbanding_card_title + " " +
            this.heavyWeightData.itemData.Quantity + "x Heavy Weights"
          }}</v-card-title>
          <HorizontalBarCard
            :barData="horisontalMultiBarData(this.heavyWeightData.Hardbanding)"
            :height="'100%'">
          </HorizontalBarCard>
        </v-card>
      </v-col>
    </v-row>

    <!-- 5th row -->
    <v-row class="ma-2">
      <v-col cols="12">
        <v-card class="pa-2 cardWidth" min-height="800px" dark color="#1C355E">
          <v-card-title>{{ this.machining_card_title + " " +
            this.heavyWeightData.itemData.Quantity + "x Heavy Weights"
          }}</v-card-title>
          <HorizontalBarCard
            :barData="horisontalMultiBarData(this.heavyWeightData.Machining_Data)"
            :height="'100%'">
          </HorizontalBarCard>
        </v-card>
      </v-col>
    </v-row>

    <!-- 6th row -->
    <v-row class="ma-2">
      <v-col cols="12">
        <OrderBreakdown :orderData="{
          listOfOrderData: this.heavyWeightData.listOfOrderData,
          orderDataTableHeaders: orderDataTableHeaders
        }" @rowClicked="handleRowClick" :equipmentType="'Heavy Weight'">

        </OrderBreakdown>
      </v-col>
    </v-row>
  </v-container>
</template>