<script>
import { CONSTANTS } from '../constants.js';
import VueApexCharts from 'vue-apexcharts';
import OrderBreakdown from './OrderBreakdown.vue';
import { barData, horisontalMultiBarData } from '../dataformatter';
import HorizontalBarCard from '../components/HorizontalBarCard.vue'
import ItemCard from '../components/ItemCard.vue'

export default {
  name: 'OCTGStatistics',
  props: {
    OCTGData: {
      type: Object,
    },
  },
  components: {
    VueApexCharts,
    ItemCard,
    OrderBreakdown,
    HorizontalBarCard
  },
  data() {
    return {
      orderDataTableHeaders: [
        {
          text: 'Order nr',
          align: 'start',
          sortable: true,
          value: 'Order_No'
        },
        { text: "Asset", sortable: true, value: "Asset" },
        { text: "Quantity", sortable: true, value: "Quantity" },
        { text: "Connection pin recut sum", sortable: true, value: "Machining_Pin_Sum_Recut" },
        { text: "Connection box recut sum", sortable: true, value: "Machining_Box_Sum_Recut" },
      ],
      machining_card_title: CONSTANTS.MACHINING_CARD_TITLE,
    }
  },
  methods: {
    barData, horisontalMultiBarData,
    handleRowClick(row) {
      this.$emit("rowClicked", row)
    }
  }
}
</script>


<template>
  <v-container>
    <v-row class="ma-2">
      <v-col cols="4">
        <v-card class="pa-2 cardWidth" min-height="400px" max-height="400px" dark
          color="#1C355E" elevation="0">
          <v-card-title>General</v-card-title>
          <ItemCard :itemObject="this.OCTGData.itemData" />
        </v-card>
      </v-col>
    </v-row>


    <v-row class="ma-2">
      <v-col cols="12">
        <v-card class="pa-2 cardWidth" min-height="800px" dark color="#1C355E">
          <v-card-title>{{ this.machining_card_title + " " +
            this.OCTGData.itemData.Quantity + "x OCTG"
          }}</v-card-title>
          <HorizontalBarCard
            :barData="horisontalMultiBarData(this.OCTGData.Machining_Data)"
            :height="'100%'">
          </HorizontalBarCard>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ma-2">
      <v-col cols="12">
        <OrderBreakdown :orderData="{
          listOfOrderData: this.OCTGData.listOfOrderData,
          orderDataTableHeaders: orderDataTableHeaders
        }" @rowClicked="handleRowClick" :equipmentType="'OCTG'">

        </OrderBreakdown>

      </v-col>
    </v-row>
  </v-container>
</template>