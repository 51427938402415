var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"justify-center align-center",attrs:{"dark":"","color":"#1C355E"}},[_c('v-toolbar',{attrs:{"dense":"","color":"transparent","elevation":"0"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.clearList(_vm.dialog)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_c('b',[_vm._v(_vm._s(_vm.Itemnr))])])],1),_c('v-card',{staticClass:"mx-auto mt-5",attrs:{"color":"transparent","width":"95%","elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.checkLocation())?_c('v-icon',{on:{"click":function($event){return _vm.getExcel(_vm.Itemnr, _vm.location)}}},[_vm._v("mdi-microsoft-excel")]):_vm._e(),(_vm.location === 'Shipped')?_c('v-icon',{on:{"click":function($event){return _vm.getExcelShippedSerials(_vm.Itemnr)}}},[_vm._v("mdi-microsoft-excel")]):_vm._e()],1),_c('v-data-table',{staticClass:"custom-table",attrs:{"loading":_vm.loader,"headers":_vm.compute_headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([(_vm.location !== 'Shipped')?{key:"item.Serial_Number",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"width":"90","small":"","light":"","color":"white"},on:{"click":function($event){return _vm.showRack(item)}}},on),[_vm._v(_vm._s(item.Serial_Number)),_c('v-spacer')],1)]}}],null,true)},[_c('span',[_vm._v("Show Rack")])])]}}:null,{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.getDTwithAttachmentPDF(item)}}},[_c('v-icon',[_vm._v("description")])],1)]}},(_vm.location !== 'Booked DT' && _vm.location !== 'Shipped')?{key:"item.DT_No",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","light":"","color":"white"},on:{"click":function($event){return _vm.serialFunction(item)}}},on),[_vm._v(_vm._s(item.DT_No)),_c('v-spacer')],1)]}}],null,true)},[_c('span',[_vm._v("Show S/N")])])]}}:null,(_vm.location === 'Shipped' && !Array.isArray(_vm.currentItem))?{key:"item.Rig",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currentItem.Rig))])]}}:null,(_vm.location === 'Shipped' && !Array.isArray(_vm.currentItem))?{key:"item.Shipped_Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currentItem.Date_Formatted))])]}}:null,(_vm.location === 'Shipped' && !Array.isArray(_vm.currentItem))?{key:"item.Customer_Name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.currentItem.Customer_Name))])]}}:null,{key:"item.Shipped",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Quantity))])]}}],null,true)},[(_vm.location === 'Shipped DT')?_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th'),_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(_vm.totalQuantity))]),_c('th',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.serialFunction2(_vm.Itemnr)}}},[_vm._v(_vm._s(_vm.totalOffshore))]),_c('th',[_vm._v(_vm._s(_vm.totalReturned))]),_c('th'),_c('th')])]):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }