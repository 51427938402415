<template>
  <v-container fluid>
    <delivery_pdf_menu v-model="pdfdialog" :item="item">
    </delivery_pdf_menu>

    <v-snackbar v-model="snackbar" color="red" top>
      {{ text }}
      <v-btn color="white" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
<!--    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search (DT number, Quantity etc..)"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="!dataLoaded"
        :headers="headers[$store.getters.getPipeType]"
        :items="$filterAsset(this.ODP_data, this.getFleet, this.getAsset, 'Asset', $store.getters.getPipeType)"
        :search="search"
      >
        <template v-slot:item.action="{ item }">
          <v-btn v-if="$store.getters.getPipeType==='BHA'" dark x-small text color="black" @click="getPDF('DT_No','ODP_BHA_Delivery_Ticket_V2',item.DT_No,'ODP_BHA_Delivery_Ticket_Print_V2','Delivery_Ticket')">
            <v-icon>description</v-icon>
          </v-btn>
          <v-btn v-else-if="$store.getters.getPipeType==='Tubular'" dark x-small text color="black" @click="getDOC(item)">
            <v-icon>description</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>-->
    <data_table
      :displayname="'Shipping'"
      :loader="get_data_load"
      :options="false"
      :headers="headers[$store.getters.getPipeType]"
      :search_text="search_text"
      :items="$filterAsset(this.ODP_data, this.getFleet, this.getAsset, 'Asset', $store.getters.getPipeType)"
      :getPDF_func="deliveryPDFchoice"
      :pdf_params="pdfparams"
      :type="$store.getters.getPipeType"
      :defaultSort="'Date'"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import data_table from '@/components/data_table.vue'
import delivery_pdf_menu from '@/components/delivery_pdf_menu.vue'
export default {
  name: 'delivery',
  components: { data_table, delivery_pdf_menu },
  data () {
    return {
      search_text: 'Search (DT number, Quantity etc..)',
      search: '',
      pdfparams: [{ Tubular: '/not existent', BHA: '/getPDF' }, 'DT_No', 'ODP_BHA_Delivery_Ticket_V2',
        'ODP_BHA_Delivery_Ticket_Print_V2', 'Delivery_Ticket'],
      headers: {
        Tubular: [
          { text: 'DT No', align: 'start', sortable: true, value: 'DT_No' },
          { text: 'Date', value: 'Date' },
          { text: 'Sent To', value: 'Sent_To' },
          { text: 'Item No', value: 'Item_No' },
          { text: 'Quantity', value: 'Quantity' },
          { text: 'Rig', value: 'Rig' },
          { text: 'Equipment', value: 'Equipment' },
          { text: 'Weight', value: 'Weight' },
          { text: 'Grade', value: 'Grade' },
          { text: 'Connection', value: 'DT_Connection' },
          { text: 'Ref', value: 'Ref' },
          { text: 'PDF', value: 'action', sortable: false }
        ],
        BHA: [
          { text: 'Delivery Ticket', align: 'start', sortable: true, value: 'DT_No' },
          { text: 'Date', value: 'Date' },
          { text: 'Receiver', value: 'Receiver' },
          { text: 'Rig', value: 'Rig' },
          // { text: 'Total Equipment', value: 'Total_Equipment' },
          { text: 'QTY', value: 'Total_Equipment' },
          { text: 'PDF', value: 'action', sortable: false }
        ]
      },
      pdfdialog: false,
      wiresling: '',
      dtpdfload: false,
      item: '',
      ODP_data: { Tubular: [], BHA: [] },
      dataLoaded: false,
      snackbar: false,
      text: ''
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getAsset', 'getAssetlist', 'getFleet', 'getPipeType', 'get_data_load'])
  },
  mounted () {
    this.$initialDataLoad(this.$route.meta.backendRoutes, { username: this.$store.getters.getUsername }, 'Date')
    // console.log(this.ODP_data)
    /*    var resp_tub = null
    this.set_data_load(true)
    axios
      .get('/getDelivery', {
        params: { username: this.$store.getters.getUsername }
      })
      .then(response => {
        if (response.data[1] === 401) {
        }
        resp_tub = response.data[0]
      })
      .then(() => {
        axios
          .get('/getDeliveryBHA', { // Get BHA data
            params: { username: this.$store.getters.getUsername }
          })
          .then(response => {
            this.ODP_data.Tubular = resp_tub
            if (response.data[1] === 401) {
            // No BHA Components, remove BHA from tabs
            }
            this.ODP_data.BHA = response.data[0]
            this.dataLoaded = true
          })
          .finally(() => {
            this.set_data_load(false)
          })
      }) */
  },
  methods: {
    ...mapActions(['set_data_load']),
    deliveryPDFchoice (type, item, pdf_params) {
      var type = this.$store.getters.getPipeType
      if (type === 'Tubular') {
        // show dialog
        this.item = item
        this.pdfdialog = true
      } else {
        // BHA, global pdf function
        this.$getPDFglobal('BHA', item, pdf_params)
      }
    }
  }
}
</script>

<style>
</style>
