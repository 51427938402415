var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-7",attrs:{"fluid":""}},[_c('bha_pdf_menu',{attrs:{"eq":_vm.eq,"subtitle":_vm.currentOrdernr,"currentOrdernr":_vm.currentOrdernr,"bha":_vm.bhaDialogO,"currentReceiveNo":_vm.currentrec,"currentCOCHB":_vm.currentCOCHB,"currentCOCMC":_vm.currentCOCMC,"itemWithProperties":_vm.itemWithProperties,"bhaInspURL":'/inspectionBHA?q='+String(_vm.currentOrdernr)},model:{value:(_vm.pdfdialogO),callback:function ($$v) {_vm.pdfdialogO=$$v},expression:"pdfdialogO"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0","tile":"","dark":""}},[_c('v-card-title',{staticClass:"align-center justify-center text-h4"},[_c('b',[_vm._v("Completed Orders")])])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"px-10",attrs:{"outlined":"","tile":"","color":"transparent","dark":""}},[_c('v-btn',{staticClass:"mb-3",attrs:{"color":"transparent","elevation":"0","to":"/orderconfirm"}},[_vm._v("Orders")]),_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"height":"250","loading":_vm.get_data_load,"headers":_vm.header_order[_vm.$store.getters.getPipeType],"items":_vm.filterOrders,"items-per-page":5},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.getPDF(_vm.$store.getters.getPipeType,item,[])}}},[_c('v-icon',[_vm._v("description")])],1)]}},{key:"item.Return_Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"px-10",attrs:{"outlined":"","tile":"","color":"transparent","dark":""}},[_c('v-btn',{staticClass:"mb-3 disable-events",attrs:{"color":"transparent","elevation":"0"}},[_vm._v("Inspection")]),_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"height":"250","loading":_vm.get_data_load,"headers":_vm.header1[_vm.$store.getters.getPipeType],"items":_vm.filterInspection,"items-per-page":5},scopedSlots:_vm._u([{key:"item.Order_No",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"light":"","color":"white","outlined":"","small":""},on:{"click":function($event){return _vm.goToInspHistory(item)}}},[_vm._v(_vm._s(item.Order_No))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.Equipment === 'Drill Pipe')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getPDFglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/getdpPDF'},'Order_No'])}}},[_c('v-icon',[_vm._v("description")])],1):(item.Equipment === 'Heavy Weight')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getPDFglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/gethwPDF'},'Order_No'])}}},[_c('v-icon',[_vm._v("description")])],1):(item.Equipment === 'Drill Collar')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getPDFglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/getdcPDF'},'Order_No'])}}},[_c('v-icon',[_vm._v("description")])],1):(_vm.$store.getters.getPipeType === 'BHA')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":"","to":'/inspectionBHA?q='+String(item.Order_No)},on:{"click":function($event){return _vm.getPDF(_vm.$store.getters.getPipeType,item,[])}}},[_c('v-icon',[_vm._v("description")])],1):_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getPDFglobal('Tubular',item,[{'Tubular':'/getoctgPDF'},'Order_No'])}}},[_c('v-icon',[_vm._v("description")])],1)]}},{key:"item.action1",fn:function(ref){
var item = ref.item;
return [(item.Equipment === 'Drill Pipe')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getEXCELglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/getdpExcel'},'Order_No'])}}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):(item.Equipment === 'Heavy Weight')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getEXCELglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/gethwExcel'},'Order_No'])}}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):(item.Equipment === 'Drill Collar')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getEXCELglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/getdcExcel'},'Order_No'])}}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):_vm._e()]}},{key:"item.Return_Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"px-10",attrs:{"outlined":"","tile":"","color":"transparent","dark":""}},[_c('v-btn',{staticClass:"mb-3 disable-events",attrs:{"color":"transparent","elevation":"0"}},[_vm._v("Machining")]),_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"height":"250","loading":_vm.get_data_load,"headers":_vm.header2[_vm.$store.getters.getPipeType],"items":_vm.filterMachining,"items-per-page":5},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.Equipment === 'Drill Pipe')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getEXCELglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/getMachExcel'},'Order_No'])}}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):(item.Equipment === 'Heavy Weight')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getEXCELglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/getMachExcelHW'},'Order_No'])}}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):(item.Equipment === 'Drill Collar')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getEXCELglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/getMachExcelDC'},'Order_No'])}}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):(_vm.$store.getters.getPipeType === 'BHA')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.getPDF(_vm.$store.getters.getPipeType,item,[])}}},[_c('v-icon',[_vm._v("description")])],1):_vm._e()]}},{key:"item.Return_Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))])]}}])})],1)],1),(_vm.$store.getters.getPipeType==='Tubular')?_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"px-10",attrs:{"outlined":"","tile":"","color":"transparent","dark":""}},[_c('v-btn',{staticClass:"mb-3 disable-events",attrs:{"color":"transparent","elevation":"0"}},[_vm._v("Hardbanding")]),_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"loading":_vm.get_data_load,"height":"250","headers":_vm.header2['Tubular'],"items":_vm.filterHardbanding,"items-per-page":5},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.Equipment === 'Drill Pipe')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getEXCELglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/getHardExcel'},'Order_No'])}}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):(item.Equipment === 'Heavy Weight')?_c('v-btn',{attrs:{"dark":"","x-small":"","text":""},on:{"click":function($event){return _vm.$getEXCELglobal(_vm.$store.getters.getPipeType,item,[{'Tubular':'/getHardExcelHW'},'Order_No'])}}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1):_vm._e()]}},{key:"item.Return_Date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Date_Formatted))])]}}],null,false,2986314010)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }