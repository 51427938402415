<template>
  <v-container fluid>
  <template>
  <v-card dark color="transparent" width="95%" class="mx-auto mt-10" elevation="0">
    <v-card-title class="text-h5">{{ this.$route.meta.displayname }}</v-card-title>
    <v-container fluid class="d-flex justify-center"
    >
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        clearable
        :label="search_text"
        hide-details
      ></v-text-field>
    </v-container>
    <v-data-table :loading="get_data_load" :headers="headers" :items="ODP_data" :search="search" :item-class="'orange'" class="elevation-1 custom-table" >
      <template v-slot:item.action="{ item }">
        <v-btn x-small text @click="getPDF(item.NCR_No)">
          <v-icon>description</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
  </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import data_table from '@/components/data_table.vue'
export default {
  name: 'qhse',
  components: { data_table },
  data () {
    return {
      pdfparams: [{ Tubular: '/getdpPDF' }, 'Order_No'],
      search_text: 'Search (NCR No, Title etc..)',
      search: '',
      headers: [
        {
          text: 'NCR No',
          align: 'start',
          sortable: true,
          value: 'NCR_No'
        },
        { text: 'Title', value: 'Title' },
        { text: 'NCR Category', value: 'NCR_Category' },
        { text: 'Location', value: 'Location' },
        { text: 'Department', value: 'Department' },
        { text: 'Status', value: 'Status' },
        { text: 'Date Reported', value: 'Date_Reported' },
        { text: 'Date Processed', value: 'Date_Processed' },
        { text: 'Date Closed', value: 'Date_Closed' },
        { text: 'PDF', value: 'action', sortable: false }
      ],
      ODP_data: []
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'get_data_load'])
  },
  mounted () {
    this.set_data_load(true)
    axios
      .get('/getQHSE', {
        params: { username: this.$store.getters.getUsername }
      })
      .then(response => {
        this.ODP_data = this.formatData(response.data, ['Date_Reported', 'Date_Processed', 'Date_Closed'])
        this.set_data_load(false)
      }) 
  },
  methods: {
    ...mapActions(['set_data_load']),
    formatData (datalist, datefields) {
      if (Array.isArray(datalist)) {
        datalist.forEach(entry => {
          for (let i = 0; i < datefields.length; i++) {
            const fieldname = datefields[i]
            entry[fieldname] = this.$formatDate(entry[fieldname])
          }
        })
      }

      return datalist
    },
    getPDF (NCR_No) {
      this.set_data_load(true)
      axios
        .get('/QHSE_getPDF', { params: { NCR_No: NCR_No, username: this.$store.getters.getUsername } })
        .then(resp => {
          window.open(resp.data.link, '_blank')
          this.set_data_load(false)
        })
      
    }
  }
}
</script>

<style scoped>

::v-deep .theme--dark.v-data-table {
  background-color: #1C355E;
}

.v-input.v-text-field.v-select {
  max-width: 300px;
}

::v-deep .v-input__slot::before {
  border-style: none !important;
}

.v-menu__content {
  text-align: start !important;
}

::v-deep .v-data-table-header {
  background-color: rgba(255, 255, 255, 0.8);
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  color: #1C355E;
}

::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #1C355E !important;
}

::v-deep .custom-table thead th{
  background-color: rgba(255, 255, 255, 0.9);
}

::v-deep .custom-table thead th:first-child{
  border-radius: 10px 0 0 0;
}

::v-deep .custom-table thead th:last-child{
  border-radius: 0 10px 0 0;
}

::v-deep tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

</style>

